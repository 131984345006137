<template>
  <div class="container is-max-desktop">
    <b-table
      custom-detail-row
      detailed
      :data="filteredEvaluations"
      :loading="isLoading"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-rounded="isPaginationRounded"
      style="overflow-x: auto"
    >
      <div class="column is-4">
        <SearchInput v-model="searchQuery" placeholder="Buscar Avaliação..." />
      </div>
      <b-table-column id="evaluation-name" field="Nome da Avaliação" label="Nome da Avaliação" v-slot="{ row }">
        <router-link v-if="row.status === 'done'" :to="{ name: 'Evaluation-Details', params: { id: row.documentId } }"
          ><b>
            <a href="#" class="has-text-info has-text-weight-bold">
              {{ row.name }}
            </a></b
          ></router-link
        >
        <p v-else>
          <span class="has-text-grey has-text-weight-bold">{{ row.name }}</span>
        </p>
      </b-table-column>
      <b-table-column field="Instância" label="Instância" v-slot="{ row }">
        <router-link v-if="row.instanceId" :to="{ name: 'Instance-Journey-Edit', params: { id: row.instanceId } }">
          <b-tag class="is-muted" type="is-light is-info has-text-weight-bold">{{ row.instance }}</b-tag>
        </router-link>
        <b-tag v-else>-</b-tag>
      </b-table-column>
      <b-table-column field="Branch" label="Branch" v-slot="{ row }">
        <router-link v-if="row.branchName" :to="{ name: 'Branch-Edit', params: { id: row.branchId } }">
          <a href="#" class="has-text-info has-text-weight-bold">
            <b-tag type="is-info is-light">{{ row.branchName }}</b-tag>
          </a>
        </router-link>
        <b-tag v-else>-</b-tag>
      </b-table-column>
      <b-table-column field="Status" label="Status" v-slot="{ row }">
        <b-tag :type="getTagType(row.status)">{{ getActionText(row.status) }}</b-tag>
      </b-table-column>
      <b-table-column field="Score" label="Score" v-slot="{ row }">
        <b-tag :type="getScoreStyle(row.score).type">{{ formatScoreAsPercentage(row.score) }}</b-tag>
      </b-table-column>
      <b-table-column v-if="isUserAdminOrManager" v-slot="{ row }" field="actions" label="Ações">
        <b-dropdown v-model="showActionsDropdown" position="is-bottom-left" trigger="is-dark">
          <b-button class="button is-small" type=" is-light" slot="trigger">
            <b-icon icon="fas fa-ellipsis-v"></b-icon>
          </b-button>
          <b-dropdown-item @click="loadEvaluationsLogs(row.documentId)" id="logView">
            <b-icon icon="database" type="is-primary" outlined></b-icon>
            <span> Ver Logs </span>
          </b-dropdown-item>
        </b-dropdown>
      </b-table-column>
      <router-link :to="{ name: 'New-Evaluation' }">
        <b-button class="fixed-bottom-right" icon-left="plus" type="is-primary"></b-button>
      </router-link>
      <template #loading>
        <div class="has-text-centered">
          <p>Carregando...</p>
        </div>
      </template>
      <template #empty>
        <div v-if="!isLoading" class="has-text-centered">Não existem registros a serem exibidos</div>
      </template>
      <template v-slot:detail="{ row }">
        <td colspan="100%">
          <div class="table-container">
            <table class="table is-hoverable">
              <caption>
                Detalhes da Avaliação
              </caption>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><span>Jornadas:</span></td>
                  <td>
                    <template v-if="row.journeys && row.journeys.length > 0">
                      <b-taglist>
                        <b-tag v-for="(journey, index) in row.journeys" :key="index" type="is-light is-info">{{ journey }}</b-tag>
                      </b-taglist>
                    </template>
                    <b-tag v-else>-</b-tag>
                  </td>
                </tr>
                <tr>
                  <td><span>Operador:</span></td>
                  <td>
                    <b-tag>{{ row.operator }}</b-tag>
                  </td>
                </tr>
                <tr>
                  <td><span>Data Criação:</span></td>
                  <td>
                    <div v-if="row.createdAt">{{ $d(new Date(row.createdAt)) }}</div>
                  </td>
                </tr>
                <tr>
                  <td><span>Data Fechamento:</span></td>
                  <td>
                    <div v-if="row.evaluatedAt">{{ $d(new Date(row.evaluatedAt)) }}</div>
                    <div v-else><b-tag>-</b-tag></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </template>
    </b-table>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import { Vue, Component, Inject } from 'vue-property-decorator';
import EvaluationService from '@/services/EvaluationService';
import { IEvaluation } from '@/models';
import { showNotification } from '@/utils/notificationUtils';
import EvaluationLogs from './EvaluationLogs.vue';
import SearchInput from '../Commons/SearchInput.vue';
import axios from 'axios';
import { hasManagerOrAdminPermissionForTool } from '@/utils/validateUserUtils';

@Component({
  components: { EvaluationLogs, SearchInput },
})
export default class EvaluationTable extends Vue {
  @Inject() private evaluationService!: EvaluationService;

  public evaluations: IEvaluation[] = [];
  public isLoading: boolean = false;
  public isFullPage: boolean = true;
  public showActionsDropdown: boolean = false;

  public currentPage: number = 1;
  public isPaginationRounded: boolean = true;
  public isPaginated: boolean = true;
  public perPage: number = 10;
  public searchQuery: string = '';
  private token: string | null = null;

  public formatScoreAsPercentage(score: number): string {
    if (score !== undefined) {
      return `${(score * 100).toFixed(0)}%`;
    }
    return 'Não calculado';
  }

  public getScoreStyle(score: number): { type: string } {
    if (score >= 0.7) {
      return { type: 'is-success is-light' };
    } else if (score >= 0.5) {
      return { type: 'is-warning is-light' };
    } else if (score < 0.5) {
      return { type: 'is-danger is-light' };
    } else {
      return { type: 'is-white is-light' };
    }
  }

  public async mounted() {
    this.token = localStorage.getItem('token');
    await this.handleLoadEvaluations();
  }

  public get filteredEvaluations(): IEvaluation[] {
    if (!this.searchQuery) return this.evaluations;
    return this.evaluations.filter((evaluation) => {
      return (evaluation.name ?? '').toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  }

  public async loadEvaluationsLogs(documentId: string) {
    try {
      const EvaluationLogsData = await this.evaluationService.getEvaluationsLogs(documentId);
      if (EvaluationLogsData) {
        this.openModalFromEvaluationLogsComponent(EvaluationLogsData, documentId);
      }
    } catch (error) {
      showNotification(this, 'Não foi possível obter a lista de logs. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  public async handleLoadEvaluations(): Promise<void> {
    try {
      this.isLoading = true;
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
      this.evaluations = await this.evaluationService.getEvaluations();
    } catch (error) {
      console.error(error);
      showNotification(this, 'Não foi possível obter a lista de avaliações. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public get isUserAdminOrManager(): boolean {
    return hasManagerOrAdminPermissionForTool('ai-tag-manager');
  }

  public getActionText(action: string): string {
    const actionTexts: { [key: string]: string } = {
      created: 'criado',
      pending: 'pendente',
      done: 'finalizado',
      error: 'erro',
    };
    return actionTexts[action] || action;
  }

  public getTagType(action: string): string {
    const actionTypes: { [key: string]: string } = {
      created: 'is-info',
      pending: 'is-warning',
      done: 'is-success',
      error: 'is-danger',
    };
    return actionTypes[action] || '';
  }

  public openModalFromEvaluationLogsComponent(logs: string[], documentId: string): void {
    this.$router.push({ name: 'Evaluation-Logs', params: { id: documentId } });
    const modalInstance = this.$buefy.modal.open({
      parent: this,
      component: EvaluationLogs,
      hasModalCard: false,
      trapFocus: true,
      canCancel: ['escape', 'button', 'outside'],
      props: { logs: logs },
      events: {
        close: () => {
          this.$router.push({ name: 'Evaluations' });
          modalInstance.close();
        },
      },
    });
  }
}
</script>
<style scoped>
.fixed-bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 100px !important;
}
</style>
