<template>
  <div class="container is-max-desktop">
    <div class="is-flex is-justify-content-space-between is-align-items-center">
      <SearchInput v-model="searchQuery" placeholder="Buscar Instância..." />
      <div>
        <b-switch v-model="filterArchived" @input="applyFilters"> Arquivadas </b-switch>
      </div>
    </div>

    <b-table
      :data="filteredInstancesJourneys"
      isLoading="isLoading"
      :hoverable="isHoverable"
      :paginated="isPaginated"
      :per-page="perPage"
      :current-page.sync="currentPage"
      :pagination-rounded="isRounded"
      :loading="isLoading"
      detailed
    >
      <b-table-column field="Instância" label="Instância" v-slot="{ row }">
        <template v-if="!row.archived">
          <router-link :to="{ name: 'Instance-Journey-Edit', params: { id: row.documentId } }">
            <a href="#" class="has-text-info has-text-weight-bold">
              {{ row.instance }}
            </a>
          </router-link>
        </template>
        <template v-else>
          <span class="has-text-info has-text-weight-bold">{{ row.instance }}</span>
        </template>
      </b-table-column>
      <b-table-column field="Nome da Branch" label="Nome da Branch" v-slot="{ row }" centered>
        <router-link :to="{ name: 'Branch-Edit', params: { id: row.branchId } }">
          <a href="#" class="has-text-info has-text-weight-bold">
            <b-tag :rounded="isRounded" type="is-light is-info">{{ row.branchName }}</b-tag>
          </a>
        </router-link>
      </b-table-column>
      <b-table-column field="Status" label="Status" v-slot="{ row }" centered>
        <b-tag :type="row.schedule ? 'is-light is-success' : 'is-light is-danger'">
          {{ row.schedule ? 'Ligado' : 'Desligado' }}
        </b-tag>
      </b-table-column>
      <b-table-column v-if="isUserAdminOrManager" v-slot="{ row }" field="actions" label="Ações">
        <b-dropdown v-model="showActionsDropdown" position="is-bottom-left" trigger="is-dark">
          <b-button class="button is-small" type=" is-light" slot="trigger">
            <b-icon icon="fas fa-ellipsis-v"></b-icon>
          </b-button>
          <b-dropdown-item v-if="!row.archived" @click="openModalFromInstanceJourneyDeleteComponent(row)" :disabled="!isUserAdminOrManager">
            <b-icon icon="box-archive" type="is-danger" outlined></b-icon>
            <span> Arquivar </span>
          </b-dropdown-item>
          <b-dropdown-item v-if="row.archived" @click="openModalFromUnarchiveInstanceJourney(row)">
            <b-icon icon="box-archive" type="is-danger" outlined></b-icon>
            <span> Desarquivar </span>
          </b-dropdown-item>
          <b-dropdown-item @click="instanceJourneyLogs(row.documentId)">
            <b-icon icon="database" type="is-primary" outlined></b-icon>
            <span> Ver Logs </span>
          </b-dropdown-item>
          <b-dropdown-item @click="redirectToInstanceJourneyStats(row.documentId)">
            <b-icon icon="square-poll-vertical" type="is-success" outlined></b-icon>
            <span> Performance </span>
          </b-dropdown-item>
        </b-dropdown>
      </b-table-column>
      <template #detail="{ row }">
        <b-taglist>
          <b-tag v-for="(journey, index) in row.journeys" :key="index" field="Jornadas" label="Jornadas" type="is-light is-primary">{{ journey }}</b-tag>
        </b-taglist>
      </template>
      <template #loading>
        <div class="has-text-centered">
          <p>Carregando...</p>
        </div>
      </template>
      <template #empty>
        <div v-if="!isLoading" class="has-text-centered">Não existem registros a serem exibidos</div>
      </template>
    </b-table>

    <router-link :to="{ name: 'Instance-Journeys-Add' }">
      <b-button class="fixed-bottom-right" icon-left="plus" type="is-primary"></b-button>
    </router-link>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Inject } from 'vue-property-decorator';
import InstanceJourneysService from '@/services/InstanceJourneysService';
import InstanceJourneyAdd from '@/app/Components/InstancesAndJourneys/InstanceJourneyAdd.vue';
import InstanceJourneyEdit from '@/app/Components/InstancesAndJourneys/InstanceJourneyEdit.vue';
import InstanceJourneyLogs from '@/app/Components/InstancesAndJourneys/InstanceJourneyLogs.vue';
import CustomPagination from '@/app/Components/Commons/CustomPagination.vue';
import SearchInput from '../Commons/SearchInput.vue';
import { IInstanceSettings } from '@/models';
import { showNotification, handleResponse, instanceJourneysServiceresponseMap } from '@/utils/notificationUtils';
import { hasManagerOrAdminPermissionForTool } from '@/utils/validateUserUtils';
import axios from 'axios';

@Component({
  components: {
    InstanceJourneyAdd,
    InstanceJourneyEdit,
    CustomPagination,
    SearchInput,
  },
})
export default class InstanceJourneysTable extends Vue {
  @Inject() private instanceJourneysService!: InstanceJourneysService;

  public instancesJourneys: IInstanceSettings[] = [];
  public selectedInstanceJourney: IInstanceSettings[] = [];
  public open: boolean = true;
  public userRole: string | null = '';

  // TABLE CONFIGS
  public filterArchived: boolean = false;
  public currentPage: number = 1;
  public isRounded: boolean = true;
  public isHoverable: boolean = true;
  public isPaginated: boolean = true;
  public perPage: number = 10;
  public isLoading: boolean = false;
  public showActionsDropdown: boolean = false;
  public searchQuery: string = '';
  private token: string | null = null;

  public get isUserAdminOrManager(): boolean {
    return hasManagerOrAdminPermissionForTool('ai-tag-manager');
  }

  public async mounted(): Promise<void> {
    this.token = localStorage.getItem('token');
    await this.loadInstancesJourneys();
  }

  public get filteredInstancesJourneys(): IInstanceSettings[] {
    if (!this.searchQuery) return this.instancesJourneys;
    return this.instancesJourneys.filter((instanceJourney) => {
      return (instanceJourney.instance ?? '').toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  }

  public async loadInstancesJourneys() {
    try {
      this.isLoading = true;
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
      this.instancesJourneys = await this.instanceJourneysService.getInstanceJourneysSettings();
    } catch (error) {
      showNotification(this, 'Não foi possível obter a lista de instâncias/jornadas. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public async instanceJourneyLogs(documentId: string) {
    try {
      const instanceJourneyLogsData = await this.instanceJourneysService.getInstanceJourneyLogs(documentId);
      if (instanceJourneyLogsData) {
        this.openModalFromInstanceJourneyLogsComponent(instanceJourneyLogsData, documentId);
      }
    } catch (error) {
      showNotification(this, 'Não foi possível obter a lista de logs. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }
  public async applyFilters(): Promise<void> {
    try {
      this.instancesJourneys = await this.instanceJourneysService.getInstanceJourneysSettings(this.filterArchived);
    } catch (error) {
      showNotification(this, 'Não foi realizar o filtro. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  public async handleDeleteInstanceJourneyConfig(selectedInstanceJourney: IInstanceSettings) {
    try {
      const response = await this.instanceJourneysService.deleteInstanceJourneysSettings(selectedInstanceJourney);
      handleResponse(this, response, instanceJourneysServiceresponseMap);
      await this.loadInstancesJourneys();
    } catch (error) {
      showNotification(this, 'Erro ao arquivar a instância. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  public async handleUnarchiveInstanceJourney(selectedInstanceJourney: IInstanceSettings) {
    try {
      const response = await this.instanceJourneysService.updateInstanceJourneysSettings(selectedInstanceJourney);
      handleResponse(this, response, instanceJourneysServiceresponseMap);
      await this.loadInstancesJourneys();
    } catch (error) {
      showNotification(this, 'Erro ao desarquivar a instância. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  public redirectToInstanceJourneyStats(documentId: string): void {
    this.$router.push({ name: 'Instance-Journey-Stats', params: { id: documentId } });
  }

  public openModalFromInstanceJourneyLogsComponent(logs: string[], documentId: string): void {
    this.$router.push({ name: 'Instance-Journeys-Logs', params: { id: documentId } });
    const modalInstance = this.$buefy.modal.open({
      parent: this,
      component: InstanceJourneyLogs,
      hasModalCard: false,
      trapFocus: true,
      canCancel: ['escape', 'button', 'outside'],
      props: {
        logs: logs,
      },
      events: {
        close: () => {
          this.$router.push({ name: 'Instance-Journeys' });
          modalInstance.close();
        },
      },
    });
  }

  public openModalFromInstanceJourneyDeleteComponent(selectedInstanceJourney: IInstanceSettings): void {
    this.$buefy.dialog.confirm({
      title: 'Atenção!',
      confirmText: 'Arquivar',
      cancelText: 'Cancelar',
      type: 'is-danger',
      message: 'Tem certeza que deseja arquivar instância?',
      onConfirm: () => this.handleDeleteInstanceJourneyConfig(selectedInstanceJourney),
    });
  }

  public openModalFromUnarchiveInstanceJourney(selectedInstanceJourney: IInstanceSettings): void {
    this.$buefy.dialog.confirm({
      title: 'Atenção!',
      confirmText: 'Desarquivar',
      cancelText: 'Cancelar',
      type: 'is-success',
      message: 'Tem certeza que deseja desarquivar instância?',
      onConfirm: () => {
        const instanceJourneyConfigToUpdate = { ...selectedInstanceJourney, archived: false };
        this.handleUnarchiveInstanceJourney(instanceJourneyConfigToUpdate);
      },
    });
  }
}
</script>
<style scoped>
.fixed-bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 100px !important;
}
</style>
