<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    <header class="content">
      <h1>Criar Branch</h1>
    </header>
    <section class="section">
      <b-field label="Nome">
        <b-input v-model="branchName"></b-input>
      </b-field>
      <b-field label="Descrição">
        <b-input v-model="branchDescription"></b-input>
      </b-field>
      <b-field label="Tags">
        <b-taginput expanded v-model="tags" :loading="tagsLoading" :disabled="tagsLoading" type="is-primary is-light" aria-close-label="Delete this tag" />
        <p class="control">
          <b-button v-if="instanceSettings" class="button is-primary" @click="suggestTags(instanceSettings)" :loading="tagsLoading" icon-left="sparkles fa-beat">Suggest Tags</b-button>

          <b-dropdown v-else aria-role="list">
            <template #trigger>
              <b-button class="button is-primary" :loading="tagsLoading" icon-right="chevron-down" icon-left="sparkles fa-beat">Suggest Tags</b-button>
            </template>
            <b-dropdown-item v-for="i in instances" :key="i.documentId" @click="suggestTags(i)" aria-role="listitem">{{ i.instance }}</b-dropdown-item>
          </b-dropdown>
        </p>
      </b-field>

      <b-collapse :open="false">
        <template #trigger>
          <b-field class="py-3">
            <b-button class="is-pulled-right" type="is-ghost">Configurações avançadas</b-button>
          </b-field>
        </template>

        <div class="columns">
          <div class="column">
            <b-field label="Serviço de classificação">
              <b-select v-model="serviceOptionSelected" placeholder="Selecione um serviço para realizar a classificação" expanded>
                <option v-for="serviceOption in aiServiceOptions" :key="serviceOption.value" :value="serviceOption.value">
                  {{ serviceOption.label }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Configuração do serviço">
              <b-select v-model="aiServiceSelected" placeholder="Configure seu serviço de classificação" expanded>
                {{ aiServiceConfig }}
                <option v-for="serviceConfig in aiServiceConfig" :key="serviceConfig.value" :value="serviceConfig.value">
                  {{ serviceConfig.label }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Prompt de sistema">
              <textarea v-model="promptSys" :maxlength="maxPromptSysLength" :class="{ textarea: true }" rows="10"></textarea>
            </b-field>
            <b-tag type="is-warning">{{ promptSysRemainingChars }} caracteres restantes</b-tag>
          </div>
        </div>

        <div class="columns">
          <div class="column">
            <b-field label="Prompt de usuário">
              <textarea v-model="promptUser" :maxlength="maxPromptUserLength" :class="{ textarea: true }" rows="5"> </textarea>
            </b-field>
            <b-tag type="is-warning"> {{ promptUserRemainingChars }} caracteres restantes</b-tag>
          </div>
        </div>
      </b-collapse>
    </section>
    <footer class="section">
      <div class="is-flex is-justify-content-space-between">
        <b-button tag="router-link" :to="{ name: 'Branches' }" type="is-danger" outlined>Cancelar</b-button>
        <b-tooltip label="Ação não permitida" type="is-light" animated v-if="!hasCreateBranchPermission">
          <b-button id="create-branch-button" :disabled="!hasCreateBranchPermission" type="is-primary" @click="handleCreateBranchConfig()" :loading="isLoading">Criar Branch</b-button>
        </b-tooltip>
        <b-button v-else id="create-branch-button" :disabled="!isAllRequiredFieldsFilled || !hasCreateBranchPermission" type="is-primary" @click="handleCreateBranchConfig()" :loading="isLoading">Criar Branch</b-button>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Inject } from 'vue-property-decorator';
import InstanceJourneysService from '@/services/InstanceJourneysService';
import BranchesService from '@/services/BranchesService';
import { OpenAiSettings, IAiConfig, IInstanceSettings, AIServicesEnum, ISelectableOption, IBranch } from '@/models';
import { handleResponse, showNotification, createBranchResponseMap } from '@/utils/notificationUtils';
import { hasManagerOrAdminPermissionForTool } from '@/utils/validateUserUtils';
import { AxiosError } from 'axios';

@Component({
  components: {},
})
export default class BranchesAdd extends Vue {
  @Inject() private branchesService!: BranchesService;
  @Inject() private instanceJourneysService!: InstanceJourneysService;

  public branchName: string = '';
  public branchDescription: string = '';
  public tags: string[] = [];
  public promptSys: string = `Você receberá uma resposta de uma pesquisa de satisfação à pergunta: "como podemos melhorar?". Nesta resposta, o cliente fala sobre com o que ele estava insatisfeito e o que pediu para melhorar.

Considere lista de itens possíveis:

{tags}

Analise a resposta e o texto acima e identifique quais as críticas e elogios mencionados explicitamente na resposta em duas listas curtas: "Elogios" e "Críticas"; cada um contendo somente itens dentre a lista de itens possíveis. Se não houver nenhum tópico explicitamente mencionado na resposta ou na lista, escreva somente N/A na lista.`;
  public promptUser: string = '{text}';
  public aiServiceSelected: string = 'gpt-4o-mini';
  public serviceOptionSelected: string = 'openAi';
  public tagsLoading = false;
  public aiServiceConfig: ISelectableOption[] = [];
  public aiServiceOptions: ISelectableOption[] = [];
  public branches: IBranch[] = [];
  public instances: IInstanceSettings[] = [];
  public maxPromptSysLength: number = 1800;
  public maxPromptUserLength: number = 1800;
  public isLoading: boolean = false;
  public instanceId: string = '';

  public instanceSettings: IInstanceSettings | null = null;

  public async mounted(): Promise<void> {
    await this.loadInstanceSettings();
    await this.loadBranches();
    await this.loadInstances();
    this.initializeAiServiceOptions();
    this.initializeAiServiceConfig();
  }

  private async loadInstanceSettings(): Promise<void> {
    this.instanceId = this.$route.query.instanceId as string;
    if (this.instanceId) {
      this.isLoading = true;
      const instanceSettings = await this.instanceJourneysService.getInstanceJourneysSetting(this.instanceId);
      this.instanceSettings = instanceSettings;
      this.branchName = instanceSettings.instance ?? '';
      this.isLoading = false;
    }
  }

  private async loadBranches(): Promise<void> {
    try {
      this.branches = await this.branchesService.getBranches();
    } catch (error) {
      showNotification(this, 'Erro ao carregar as branches', 'is-danger', 'is-top', 5000);
    }
  }

  private async loadInstances(): Promise<void> {
    try {
      this.instances = await this.instanceJourneysService.getInstanceJourneysSettings();
    } catch (error) {
      showNotification(this, 'Erro ao carregar as instâncias', 'is-danger', 'is-top', 5000);
    }
  }

  public initializeAiServiceOptions(): void {
    this.aiServiceOptions = [{ value: 'openAi', label: 'OpenAI' }];
  }

  public initializeAiServiceConfig(): void {
    this.aiServiceConfig = [
      { value: 'gpt-3.5-turbo', label: 'GPT 3.5 Turbo' },
      { value: 'gpt-4-1106-preview', label: 'GPT 4 Turbo' },
      { value: 'gpt-4o', label: 'GPT 4 Omni' },
      { value: 'gpt-4o-mini', label: 'GPT 4o Mini' },
    ];
  }

  public get aiConfig(): IAiConfig {
    return {
      documentId: this.instanceId,
      name: this.branchName,
      description: this.branchDescription,
      tags: this.tags,
      aiDefaultService: this.selectedService,
      aiServiceSettings: { openAi: this.openAiSettings },
    };
  }

  public get instanceJourneyConfig(): IInstanceSettings {
    return {
      documentId: this.instanceId,
      instance: this.instanceSettings?.instance ?? '',
      branchName: this.branchName,
      journeys: this.instanceSettings?.journeys ?? [],
    };
  }

  public get selectedService(): AIServicesEnum {
    return this.serviceOptionSelected as unknown as AIServicesEnum;
  }

  public get openAiSettings(): OpenAiSettings {
    return {
      model: this.aiServiceSelected,
      promptSys: this.promptSys,
      promptUser: this.promptUser,
      positivesHeaders: ['Elogios', 'positivos', 'positives'],
      negativeHeaders: ['Críticas', 'negativos', 'negatives'],
      neutralHeaders: ['Neutros', 'neutrals'],
      parser: 'list',
    };
  }

  get promptSysRemainingChars(): number {
    return this.maxPromptSysLength - this.promptSys.length;
  }

  get promptUserRemainingChars(): number {
    return this.maxPromptUserLength - this.promptUser.length;
  }

  get hasCreateBranchPermission(): boolean {
    return hasManagerOrAdminPermissionForTool('ai-tag-manager');
  }

  public get isAllRequiredFieldsFilled(): boolean {
    return this.branchName.trim() !== '' && this.promptSys.trim() !== '' && this.promptUser.trim() !== '' && this.tags.length > 0;
  }

  public async handleCreateBranchConfig(): Promise<void> {
    try {
      this.isLoading = true;
      await this.branchesService.createBranchConfig(this.aiConfig);
      handleResponse(this, 201, createBranchResponseMap);
      this.navigateToNewEvaluation();
    } catch (error) {
      if (error instanceof AxiosError) {
        handleResponse(this, error.response?.data.statusCode, createBranchResponseMap);
      }
    } finally {
      this.isLoading = false;
    }
  }

  private navigateToNewEvaluation(): void {
    this.$router.push({ name: 'New-Evaluation' });
  }

  public async suggestTags(instance: IInstanceSettings): Promise<void> {
    showNotification(this, 'Sugerindo tags... este passo pode demorar algum tempo', 'is-info', 'is-top', 5000);
    try {
      this.tagsLoading = true;
      if (instance.instance && instance.journeys) {
        this.tags = await this.branchesService.getTagSuggestions(instance.instance, instance.journeys);
      }
    } catch (e) {
      console.error(e);
      showNotification(this, 'Não foi possível sugerir tags', 'is-warning', 'is-top', 5000);
    } finally {
      this.tagsLoading = false;
    }
  }
}
</script>
<style scoped>
::v-deep .collapse-content {
  margin-top: 3rem;
}
</style>
