import BranchEdit from '@/app/Components/Branch/BranchEdit.vue';
import BranchLogs from '@/app/Components/Branch/BranchLogs.vue';
import BranchAdd from '@/app/Components/Branch/BranchAdd.vue';
import BranchTable from '@/app/Components/Branch/BranchTable.vue';
import Validate from '@/app/Components/Validate/Validate.vue';
import EvaluationAdd from '@/app/Components/Evaluations/EvaluationAdd.vue';
import EvaluationResult from '@/app/Components/Evaluations/EvaluationResult.vue';
import EvaluationTable from '@/app/Components/Evaluations/EvaluationTable.vue';
import InstanceJourneyAdd from '@/app/Components/InstancesAndJourneys/InstanceJourneyAdd.vue';
import InstanceJourneyEdit from '@/app/Components/InstancesAndJourneys/InstanceJourneyEdit.vue';
import InstanceJourneyLogs from '@/app/Components/InstancesAndJourneys/InstanceJourneyLogs.vue';
import InstanceJourneysTable from '@/app/Components/InstancesAndJourneys/InstancesJourneysTable.vue';
import InstanceJourneyStats from '@/app/Components/InstancesAndJourneys/InstanceJourneyStats.vue';
import EvaluationLogs from '@/app/Components/Evaluations/EvaluationLogs.vue';
import ForceClassification from '@/app/Components/Commons/ForceClassification.vue';
import Vue from 'vue';
import Router from 'vue-router';
import { handleTokenError, redirectToAuth, validateToken } from './routerUtils';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', redirect: '/instance-journeys' },
    { path: '/instance-journeys', name: 'Instance-Journeys', component: InstanceJourneysTable, children: [{ path: '/instance-journeys/:id/logs', name: 'Instance-Journeys-Logs', component: InstanceJourneyLogs }] },
    { path: '/instance-journeys/add', name: 'Instance-Journeys-Add', component: InstanceJourneyAdd },
    { path: '/instance-journeys/:id', name: 'Instance-Journey-Edit', component: InstanceJourneyEdit, props: true },
    { path: '/instance-journeys/:id/stats', name: 'Instance-Journey-Stats', component: InstanceJourneyStats },
    { path: '/branches', name: 'Branches', component: BranchTable, children: [{ path: '/branches/:id/logs', name: 'Branch-Logs', component: BranchLogs }] },
    { path: '/branches/new', name: 'Branch-Add', component: BranchAdd },
    { path: '/branches/:id', name: 'Branch-Edit', component: BranchEdit, props: true },
    { path: '/evaluations/', name: 'Evaluations', component: EvaluationTable, children: [{ path: '/evaluations/:id/logs', name: 'Evaluation-Logs', component: EvaluationLogs }] },
    { path: '/evaluations/new-evaluation', name: 'New-Evaluation', component: EvaluationAdd },
    { path: '/evaluations/:id', name: 'Evaluation-Details', component: EvaluationResult },
    { path: '/evaluations/:id/imported-comments', name: 'Imported-Comments', component: EvaluationAdd },
    { path: '/evaluations/:id/comments', name: 'Evaluation-Comments', component: EvaluationAdd },
    { path: '/evaluations/:id/model-branch', name: 'Model-Branch', component: EvaluationAdd },
    { path: '/validate', name: 'Validate', component: Validate },
    { path: '/force-classification', name: 'Force-Classification', component: ForceClassification },
  ],
});

router.beforeEach((to, from, next): void => {
  if (to.path == '/validate/') {
    next();
    return;
  }
  const token = getToken();

  if (!token) {
    redirectToAuth();
    return;
  }

  try {
    validateToken(token);
    next();
  } catch (error) {
    handleTokenError(error);
    redirectToAuth();
  }
});

function getToken(): string | null {
  // Set seu token do ferramentas para testar local;
  // localStorage.setItem('token', '');
  return localStorage.getItem('token');
}

export default router;
