<template>
  <div>
    <b-step-item step="4" label="Configurações da Branch" :clickable="false">
      <div class="section">
        <b-field v-if="branchesConfig && branchesConfig.length" label="Branch">
          <b-select id="evaluation-config-branch-step" v-model="selectedBranch" expanded>
            <option v-for="(branch, index) in branchesConfig" :key="index" :value="branch">
              {{ branch.name }}
            </option>
          </b-select>
        </b-field>
        <div class="content" v-if="selectedBranch && selectedBranch.aiServiceSettings && selectedBranch.aiServiceSettings.openAi">
          <p><strong>Serviço de Classificação:</strong> {{ selectedBranch && selectedBranch.aiDefaultService }}</p>
          <p><strong>Modelo:</strong> {{ selectedBranch && selectedBranch.aiServiceSettings.openAi.model }}</p>
          <p><strong>Tags:</strong></p>
          <b-taglist>
            <b-tag v-for="tag in selectedBranch && selectedBranch.tags" :key="tag" type="is-primary is-light" rounded>{{ tag }}</b-tag>
          </b-taglist>
          <p><strong>Prompt System:</strong></p>
          <blockquote>{{ selectedBranch && selectedBranch.aiServiceSettings.openAi.promptSys }}</blockquote>
          <p><strong>Prompt User:</strong></p>
          <blockquote>{{ selectedBranch && selectedBranch.aiServiceSettings.openAi.promptUser }}</blockquote>
        </div>
      </div>

      <div class="section" v-if="selectedBranch">
        <b-button @click="performPreview" icon-left="rotate" :loading="isLoadingPreview || isLoading">Testar um comentário</b-button>
        <div id="test-comment" class="box content" v-if="testComment && hasTestCommentData">
          <h3>Detalhes do Comentário Testado:</h3>
          <p><strong>Instância:</strong> {{ testComment.instance }}</p>
          <p><strong>Jornada:</strong> {{ testComment.journey }}</p>
          <p><strong>Comentário:</strong></p>
          <blockquote>{{ testComment.text }}</blockquote>
          <b-taglist v-if="testComment.tags">
            <b-tag v-for="(tag, index) in testComment.tags" :key="index" :type="getTagStyle(tag.sentiment).type" :icon="getTagStyle(tag.sentiment).icon" rounded>{{ tag.tag }} </b-tag>
          </b-taglist>
        </div>
      </div>
      <div class="is-flex is-justify-content-space-between mt-3">
        <b-button type="is-danger" icon-left="backward" @click="$emit('previousStep')">Voltar</b-button>
        <b-button id="rate-button" :disabled="!selectedBranch" type="is-primary" :loading="isLoading" icon-left="forward" @click="openDialogToConfirmEvaluateComments">Avaliar</b-button>
      </div>
    </b-step-item>
  </div>
</template>

<script lang="ts">
import { IBranch, TestComment } from '@/models';
import { showNotification } from '@/utils/notificationUtils';
import { Vue, Component, Prop, Inject, Watch } from 'vue-property-decorator';
import EvaluationService from '@/services/EvaluationService';
import BranchesService from '@/services/BranchesService';
import { AxiosError } from 'axios';

@Component({ components: {} })
export default class EvaluationConfigBranchStep extends Vue {
  @Inject() private evaluationService!: EvaluationService;
  @Inject() private branchesService!: BranchesService;

  @Prop() public readonly evaluationId!: string;

  public branchesConfig: IBranch[] = [];
  public activeTabIndex: number = 0;
  public selectedBranch: IBranch | null = null;
  public isLoadingPreview: boolean = false;
  public isLoading: boolean = false;
  public testComment: TestComment = {
    instance: '',
    journey: '',
    tags: [],
    text: '',
  };

  public mounted(): void {
    this.loadBranches();
  }

  public async loadBranches(): Promise<void> {
    try {
      const branchesData = await this.branchesService.getBranches();
      if (branchesData) {
        this.branchesConfig = branchesData;
      }
    } catch (error) {
      showNotification(this, 'Não foi possível obter a lista de branches. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  public async performPreview(): Promise<void> {
    if (this.evaluationId && this.selectedBranch) {
      this.isLoadingPreview = true;
      try {
        await this.handleTestCommentForPreview(this.evaluationId, this.selectedBranch);
      } catch (e) {
        showNotification(this, 'Não foi possível testar o comentário. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
      }
      this.isLoadingPreview = false;
    } else {
      showNotification(this, 'Dados faltando para a realização do preview', 'is-danger', 'is-top', 5000);
    }
  }

  public async handleTestCommentForPreview(evaluationId: string, selectedBranch: IBranch): Promise<void> {
    try {
      const response = await this.evaluationService.submitTestCommentForPreview(evaluationId, selectedBranch);
      this.testComment = response;
      showNotification(this, 'Comentário testado com sucesso', 'is-success', 'is-top', 5000);
    } catch (error) {
      showNotification(this, 'Não foi possível testar o comentário. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  public get hasTestCommentData() {
    return this.testComment && (this.testComment.instance || this.testComment.journey || (this.testComment.tags && this.testComment.tags.length > 0));
  }

  public openDialogToConfirmEvaluateComments(): void {
    const totalEvaluations = 10;
    this.$buefy.dialog.confirm({
      title: 'Atenção',
      message: `Voce pode criar ${totalEvaluations} avaliações por dia, deseja continuar?`,
      confirmText: 'Confirmar',
      cancelText: 'Cancelar',
      type: 'is-warning',
      hasIcon: true,
      onConfirm: () => {
        if (this.evaluationId && this.selectedBranch) {
          this.handleSubmitEvaluationConfig(this.evaluationId, this.selectedBranch);
        }
      },
    });
  }

  public async handleSubmitEvaluationConfig(evaluationId: string, selectedBranch: IBranch): Promise<void> {
    this.isLoading = true;
    try {
      showNotification(this, 'Configuração de avaliação enviada com sucesso, Este passo pode demorar algum tempo.', 'is-warning', 'is-top', 5000);
      await this.evaluationService.submitEvaluationConfig(evaluationId, selectedBranch);
      this.$router.push({ name: 'Evaluation-Details', params: { id: this.evaluationId } });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data.error === 'not_enough_quota') {
          showNotification(this, 'Suas quotas acabaram, tente novamente amanhã.', 'is-danger', 'is-top', 5000);
        } else {
          showNotification(this, 'Não foi possível enviar a configuração de avaliação. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
        }
      }
    }
    this.isLoading = false;
  }

  public getTagStyle(sentiment: string | undefined) {
    switch (sentiment) {
      case 'positive':
        return { icon: 'thumbs-up', type: 'is-success is-light' };
      case 'negative':
        return { icon: 'thumbs-down', type: 'is-danger is-light' };
      case 'neutral':
        return { icon: 'thumbs-info', type: 'is-warning is-light' };
      default:
        return {};
    }
  }

  @Watch('activeTabIndex')
  onTabChange(newVal: number) {
    const selectedBranch = this.branchesConfig[newVal];
    this.selectedBranch = selectedBranch;
  }
}
</script>
