import { AxiosError, AxiosResponse } from 'axios';
import { IInstanceSettings, ITagStatistics, Params, SolucxInstance } from '@/models';
import BaseService from '@/services/BaseService';

export default class InstanceJourneyService extends BaseService {
  public async getInstanceJourneysSettings(archived?: boolean): Promise<IInstanceSettings[]> {
    const params: Params = {
      archived: archived,
    };

    const { data } = await this.client.get<IInstanceSettings[]>('/instance-settings', { params });
    return data;
  }

  public async getInstanceJourneysSetting(documentId: string): Promise<IInstanceSettings> {
    const { data } = await this.client.get<IInstanceSettings>('/instance-settings/' + documentId);
    return data;
  }

  public async getInstanceJourneyLogs(documentId: string) {
    try {
      const response = await this.client.get(`/instance-settings/${documentId}/logs`);
      return response.data;
    } catch (error) {
      return (error as AxiosError).response?.status;
    }
  }

  public async createInstanceJourneysSettings(instanceJourneyConfig: IInstanceSettings): Promise<IInstanceSettings> {
    const response = await this.client.post('/instance-settings', instanceJourneyConfig);
    return response.data;
  }

  public async updateInstanceJourneysSettings(instanceJourneyConfig: IInstanceSettings) {
    const { documentId } = instanceJourneyConfig;
    const response = await this.client.put(`/instance-settings/${documentId}`, instanceJourneyConfig);
    return response.data;
  }

  public async deleteInstanceJourneysSettings(selectedInstanceJourney: IInstanceSettings) {
    const { documentId } = selectedInstanceJourney;
    try {
      const response = await this.client.delete(`/instance-settings/${documentId}`);
      return response.status;
    } catch (error) {
      return (error as AxiosError).response?.status;
    }
  }

  public async getTagsPerformance(documentId: string, startDate: string, endDate: string): Promise<ITagStatistics[]> {
    const response = await this.client.get(`/instance-settings/${documentId}/stats?startDate=${startDate}&endDate=${endDate}`);
    return response.data;
  }

  public async downloadEvaluationResultsToCSV(documentId: string, startDate: string, endDate: string): Promise<AxiosResponse> {
    const response = await this.client.get<string>(`/instance-settings/${documentId}/stats/csv?startDate=${startDate}&endDate=${endDate}`, { responseType: 'blob' });
    return response;
  }

  public async getInstancesJourneysSolucx(): Promise<SolucxInstance[]> {
    const { data } = await this.client.get<SolucxInstance[]>('/solucx-instances');
    return data;
  }

  public async addNewTagOnTheBranch(documentId: string, tagName: string) {
    const response = await this.client.post(`/instance-settings/${documentId}/tags`, { tagName });
    return response.data;
  }
}

export interface TagSuggestion {
  tag: string;
  subTag: string;
  recurrency: string;
  sentiment: string;
}
