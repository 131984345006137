<template>
  <div>
    <b-field>
      <b-datepicker v-model="localDateRange" @input="handleDateRangeChange" ref="datepicker" placeholder="Selecione um período" range></b-datepicker>
      <b-button @click="toggleDatepicker" icon-left="calendar" type="is-primary" />
    </b-field>
    <div>
      Ultimos <b-tag type="is-info is-light">{{ daysBetween }}</b-tag> dias
    </div>
    <div>
      <b>Volume de:</b>
      <b-tag type="is-info is-light">{{ formattedStartDate }}</b-tag>
      / até:
      <b-tag type="is-info is-light">{{ formattedEndDate }}</b-tag>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class DateRangePicker extends Vue {
  @Prop({ default: [] }) readonly selectedDateRange!: Array<Date>;

  public localDateRange: Array<Date> = [];

  public mounted() {
    this.localDateRange = this.selectedDateRange;
  }

  get daysBetween(): number {
    if (this.localDateRange.length === 2) {
      const [start, end] = this.localDateRange;
      return Math.ceil((end.getTime() - start.getTime()) / (1000 * 3600 * 24));
    }
    return 0;
  }

  get formattedStartDate(): string {
    if (this.localDateRange.length > 0) {
      return this.formatDateToYYYYMMDD(this.localDateRange[0]);
    }
    return '';
  }

  get formattedEndDate(): string {
    if (this.localDateRange.length > 1) {
      return this.formatDateToYYYYMMDD(this.localDateRange[1]);
    }
    return '';
  }

  toggleDatepicker(): void {
    const datepicker = this.$refs.datepicker as any;
    if (datepicker && typeof datepicker.toggle === 'function') {
      datepicker.toggle();
    }
  }

  private formatDateToYYYYMMDD(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  public handleDateRangeChange(): void {
    if (this.localDateRange.length === 2) {
      const formattedStartDate = this.formatDateToYYYYMMDD(this.localDateRange[0]);
      const formattedEndDate = this.formatDateToYYYYMMDD(this.localDateRange[1]);

      this.$emit('dateRangeChange', [formattedStartDate, formattedEndDate]);
    }
  }
}
</script>
