<template>
  <div>
    <header class="modal-card-head">
      <p class="modal-card-title">Logs de Avaliações</p>
      <button type="button" class="delete" @click="$emit('close')" />
    </header>
    <section class="modal-card-body">
      <div v-if="formattedLogs.length">
        <b-table :data="formattedLogs" cell-class="limited-text" width="100px">
          <b-table-column field="action" label="Ação" v-slot="{ row }" centered>
            <b-tag :type="getTagType(row.action)">{{ getActionText(row.action) }}</b-tag>
          </b-table-column>
          <b-table-column field="user" label="Operador" v-slot="{ row }" centered>
            <b-tag>{{ row.username }}</b-tag>
          </b-table-column>
          <b-table-column field="date" label="Data da Alteração" v-slot="{ row }" centered>
            <b-tag type="is-light is-info">{{ row.timestamp }}</b-tag>
          </b-table-column>
        </b-table>
      </div>
      <div v-else class="has-text-centered">
        <div class="block">
          <b-icon icon="folder-open" size="is-large"></b-icon>
        </div>
        <h1>Não existem logs a serem exibidos.</h1>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Logs } from '@/models';

@Component({
  components: {},
})
export default class EvaluationLogs extends Vue {
  @Prop() public readonly logs!: Logs[];

  get formattedLogs() {
    return this.logs.map((log) => ({
      ...log,
      actionText: this.getActionText(log.action),
      tagType: this.getTagType(log.action),
      timestamp: this.formatDate(log.timestamp),
    }));
  }

  private formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };

    const date = new Date(dateString);
    return date.toLocaleString('pt-BR', options);
  }

  public getActionText(action: string): string {
    const words = action.split(/-|_/);

    const formattedAction = words.map((word) => word.toLowerCase()).join(' ');

    const actionTexts: { [key: string]: string } = {
      created: 'criado',
      imported: 'importou comentários',
      discarded: 'descartou comentários',
      'evaluated one': 'avaliou um comentário',
      'evaluated many': 'avaliou vários comentários',
      downloaded: 'baixou CSV de avaliações',
      approved: 'aprovou uma avaliação',
      'added observation': 'adicionou observação',
      'calculated score': 'calculou score',
    };

    return actionTexts[formattedAction] || action;
  }

  public getTagType(action: string): string {
    const actionTypes: { [key: string]: string } = {
      created: 'is-light is-info',
    };
    return actionTypes[action] || '';
  }
}
</script>
