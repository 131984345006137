/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from 'axios';

export default abstract class BaseService {
  constructor(protected client: AxiosInstance, userToken: string) {
    this.setUserToken(userToken);
  }

  public setUserToken(userToken: string): void {
    this.client.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
  }

  protected async _post<T>(url: string, data?: Record<string, unknown>): Promise<T> {
    const { data: response } = await this.client.post<T>(url, data);
    return response;
  }

  protected async _put<T>(url: string, data?: Record<string, unknown>): Promise<T> {
    const { data: response } = await this.client.put<T>(url, data);
    return response;
  }

  protected async _get<T>(url: string, params?: Record<string, unknown>): Promise<T> {
    const defaults = this.client.defaults.params;
    const { data: response } = await this.client.get<T>(url, { params: { ...defaults, ...params } });
    return response;
  }

  protected async _del<T>(url: string): Promise<T> {
    const { data: response } = await this.client.delete(url);
    return response;
  }

  protected async _patch<T>(url: string, data?: Record<string, unknown>): Promise<T> {
    const { data: response } = await this.client.patch<T>(url, data);
    return response;
  }
}
