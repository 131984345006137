<template>
  <div>
    <p class="control has-icons-left">
      <input id="search-input" class="input" type="text" :placeholder="placeholder" @input="updateQuery" v-model="searchQuery" />
      <span class="icon is-small is-left">
        <i class="fas fa-search"></i>
      </span>
    </p>
  </div>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({ components: {} })
export default class SearchInput extends Vue {
  @Prop({ required: false, default: 'Buscar...' }) public readonly placeholder!: string;

  public searchQuery: string = '';

  public updateQuery(): void {
    this.$emit('input', this.searchQuery);
  }
}
</script>
