<template>
  <div class="columns is-justify-content-right px-4 py-4">
    <div class="column is-2 has-text-right">
      <b-select v-model="selectedPerPage" @input="onSelectedPerPageChanged">
        <option v-for="option in paginationOptions" :key="option" :value="option">{{ option }}</option>
      </b-select>
    </div>
    <div class="column is-5">
      <b-pagination v-model="currentPage" :total="totalItems" :rounded="isRounded" order="is-centered" @change="onCurrentPageChanged"> </b-pagination>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CustomPagination extends Vue {
  @Prop() public readonly paginationOptions!: number[];
  @Prop({ default: false }) public readonly isRounded!: boolean;
  @Prop({ default: 0 }) public readonly totalItems!: number;

  public currentPage: number = 1;
  public selectedPerPage: number = 10;

  @Watch('currentPage')
  onCurrentPagePropChange(newValue: number) {
    this.currentPage = newValue;
  }

  @Watch('selectedPerPage')
  onSelectedPerPagePropChange(newValue: number) {
    this.selectedPerPage = newValue;
  }

  public onSelectedPerPageChanged(selectedPerPage: number) {
    this.$emit('perPageValue', selectedPerPage);
  }

  public onCurrentPageChanged(currentPage: number) {
    this.$emit('currentPageValue', currentPage);
  }
}
</script>
<style scoped></style>
