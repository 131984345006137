import { GlobalPositions } from 'buefy/types/helpers';
import BuefyComponent from 'buefy';

interface BuefyComponent {
  $buefy: {
    notification: {
      open: (options: NotificationOptions) => void;
    };
  };
}

interface ResponseMap {
  [key: number]: { message: string; type: string };
}

interface NotificationOptions {
  message: string;
  type: string;
  position: GlobalPositions;
  duration: number;
}

export const createBranchResponseMap: { [key: number]: { message: string; type: string } } = {
  201: { message: 'Branch criada com sucesso', type: 'is-success' },
  400: { message: 'Somente administradores podem alterar as configurações de uma instância ou branch que está sendo utilizada', type: 'is-danger' },
  404: { message: 'Não foi possível obter as configurações, tente novamente mais tarde', type: 'is-warning' },
  406: { message: 'Erro ao criar a branch. Seu Token não possuí todos os campos necessários', type: 'is-warning' },
  409: { message: 'Erro ao criar a branch. Essa branch já existe', type: 'is-warning' },
};

export const updateBranchResponseMap: { [key: number]: { message: string; type: string } } = {
  200: { message: 'Branch atualizada com sucesso', type: 'is-success' },
  400: { message: 'Somente administradores podem alterar as configurações de uma instância ou branch que está sendo utilizada', type: 'is-danger' },
  404: { message: 'Não foi possível obter as configurações, tente novamente mais tarde', type: 'is-warning' },
  406: { message: 'Erro ao criar a branch. Seu Token não possuí todos os campos necessários', type: 'is-warning' },
  409: { message: 'Não é possível alterar o nome para um que já existe', type: 'is-warning' },
};

export const archiveBranchResponseMap: { [key: number]: { message: string; type: string } } = {
  204: { message: 'Branch arquivada com sucesso', type: 'is-success' },
  400: { message: 'Somente administradores podem arquivar uma instância ou branch que está sendo utilizada', type: 'is-danger' },
  404: { message: 'Não foi possível obter as configurações, tente novamente mais tarde', type: 'is-warning' },
  406: { message: 'Erro ao criar a branch. Seu Token não possuí todos os campos necessários', type: 'is-warning' },
};

export const instanceJourneysServiceresponseMap: { [key: number]: { message: string; type: string } } = {
  200: { message: 'Instância atualizada com sucesso', type: 'is-success' },
  201: { message: 'Instância criada com sucesso', type: 'is-success' },
  204: { message: 'Instância arquivada com sucesso', type: 'is-success' },
  400: { message: 'Não é possível alterar as configurações de uma instância ou branch que está sendo utilizada, somente usuário administradores podem realizar tal ação', type: 'is-danger' },
  404: { message: 'Não foi possível obter as configurações, tente novamente mais tarde', type: 'is-warning' },
  406: { message: 'Erro ao criar a Instância. Seu Token não possuí todos os campos necessários', type: 'is-warning' },
  409: { message: 'Erro ao criar a Instância. Essa Instância já existe', type: 'is-warning' },
  500: { message: 'Não foi possível processar sua solicitação, tente novamente mais tarde', type: 'is-danger' },
};

export function showNotification(instance: BuefyComponent, message: string, type: string, position: GlobalPositions, duration: number) {
  instance.$buefy.notification.open({
    message: message,
    type: type,
    position: position,
    duration: duration,
  });
}

export function handleResponse(context: BuefyComponent, response: number | undefined, responseMap: ResponseMap): void {
  const defaultResponse = { message: 'Não foi possível processar sua solicitação, tente novamente mais tarde', type: 'is-danger' };
  const responseSettings = response !== undefined ? responseMap[response] || defaultResponse : defaultResponse;
  showNotification(context, responseSettings.message, responseSettings.type, 'is-top', 5000);
}
