import Payload, { Permission, PermissionWrapper } from './permissions';
import { jwtDecode } from 'jwt-decode';

interface UserDetails extends Payload {
  permissions: PermissionWrapper[];
}

function validateToken(token: string): void {
  const userDetails: UserDetails = jwtDecode(token) as UserDetails;
  checkTokenExpiration(userDetails);
  checkUserPermission(userDetails);
}

function checkTokenExpiration(userDetails: UserDetails): void {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  if (!userDetails.exp) {
    throw new Error('Propriedade exp ausente no token');
  }

  if (userDetails.exp && userDetails.exp < currentTimestamp) {
    throw new Error('Token expirado');
  }
}

function checkUserPermission(userDetails: UserDetails): void {
  const acceptedRoles = ['user', 'manager', 'admin'];

  if (!userDetails || !userDetails.permissions) {
    throw new Error('A propriedade permissions é obrigatória no token');
  }

  if (!userDetails.permissions.length) {
    throw new Error('Lista de permissões não pode estar vazia');
  }

  const hasPermission = userDetails.permissions.some((p: { permission: Permission }) => p.permission.tool.resource === 'ai-tag-manager' && acceptedRoles.includes(p.permission.name));

  if (!hasPermission) {
    throw new Error('Sem permissão para o recurso ai-tag-manager');
  }
}

function handleTokenError(error: unknown): void {
  if (error instanceof Error) {
    console.error(error.message);
  } else {
    console.error(error);
  }
}

function redirectToAuth(): void {
  const baseUrl = 'https://ferramentas.';
  const domain = window.location.href.includes('staging') ? 'staging.solucx.com.br' : 'solucx.com.br';
  window.location.href = `${baseUrl}${domain}/auth/#/`;
}

export { UserDetails, validateToken, checkTokenExpiration, checkUserPermission, handleTokenError, redirectToAuth };
