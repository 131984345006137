<template>
  <div id="evaluation-name-step">
    <b-loading :active="isLoading"></b-loading>
    <b-step-item step="1" label="Nome da Avaliação" :clickable="false">
      <div class="section">
        <b-field>
          <b-input v-model="evaluationName" type="text"></b-input>
        </b-field>
        <div class="mt-3 has-text-right">
          <b-button :disabled="!evaluationName.trim() || !hasCreateEvaluation" type="is-primary" @click="handleCreateNewEvaluation(evaluationName)" icon-left="forward">Continuar</b-button>
        </div>
      </div>
    </b-step-item>
  </div>
</template>

<script lang="ts">
import { showNotification } from '@/utils/notificationUtils';
import { Vue, Component, Inject } from 'vue-property-decorator';
import { hasManagerOrAdminPermissionForTool } from '@/utils/validateUserUtils';
import EvaluationService from '@/services/EvaluationService';

@Component({ components: {} })
export default class EvaluationNameStep extends Vue {
  @Inject() private evaluationService!: EvaluationService;

  public evaluationName: string = '';
  public evaluationId: string = '';
  public isLoading: boolean = false;

  public get hasCreateEvaluation() {
    return hasManagerOrAdminPermissionForTool('ai-tag-manager');
  }

  public async handleCreateNewEvaluation(evaluationName: string): Promise<void> {
    if (!evaluationName.trim()) {
      return;
    }
    try {
      this.isLoading = true;
      const response = await this.evaluationService.createNewEvaluation(evaluationName);
      this.evaluationId = response;
      this.$emit('nextStep', { evaluationId: this.evaluationId });
    } catch (error) {
      showNotification(this, 'Não foi possível criar a avaliação. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }
}
</script>
