import Payload from '@/router/permissions';
import { jwtDecode } from 'jwt-decode';

export function getDecodedToken(): Payload | null {
  const token = localStorage.getItem('token');
  if (token) {
    return jwtDecode<Payload>(token);
  }
  return null;
}

export function hasManagerOrAdminPermissionForTool(toolResource: string): boolean {
  const userDetails = getDecodedToken();
  return (
    userDetails?.permissions?.some(
      (permissionWrapper) => (permissionWrapper.permission.name === 'manager' || permissionWrapper.permission.name === 'admin') && permissionWrapper.permission.tool.resource === toolResource,
    ) || false
  );
}

export function isAdminForTool(toolResource: string): boolean {
  const userDetails = getDecodedToken();
  return userDetails?.permissions?.some((permissionWrapper) => permissionWrapper.permission.name === 'admin' && permissionWrapper.permission.tool.resource === toolResource) || false;
}

export function isManagerForTool(toolResource: string): boolean {
  const userDetails = getDecodedToken();
  return userDetails?.permissions?.some((permissionWrapper) => permissionWrapper.permission.name === 'manager' && permissionWrapper.permission.tool.resource === toolResource) || false;
}

export function isUserForTool(toolResource: string): boolean {
  const userDetails = getDecodedToken();
  return userDetails?.permissions?.some((permissionWrapper) => permissionWrapper.permission.name === 'user' && permissionWrapper.permission.tool.resource === toolResource) || false;
}
