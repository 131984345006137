<template>
  <div>
    <header class="content">
      <h1>Criar Configuração de Instância e Jornada</h1>
    </header>
    <section class="section">
      <b-field label="Nome da instância" :type="{ 'is-danger': $v.selectedInstance.$error }" :message="$v.selectedInstance.$error ? 'O nome da instância é obrigatório' : ''">
        <b-autocomplete v-model="selectedInstance" :data="filteredInstanceNames" type="text" placeholder="Selecione uma instância..." icon="magnifying-glass" :open-on-focus="openOnFocus" clearable></b-autocomplete>
      </b-field>
      <b-field label="Jornadas" :type="{ 'is-danger': $v.journeys.$error }" :message="$v.journeys.$error ? 'É necessário selecionar ao menos uma jornada' : ''">
        <b-taginput
          v-model="journeys"
          :disabled="!selectedInstance"
          autocomplete
          placeholder="Selecione uma jornada..."
          :open-on-focus="openOnFocus"
          :data="filteredInstanceJourneys"
          type="is-primary is-light"
          icon="magnifying-glass"
          aria-close-label="Delete this tag"
          @typing="handleSearchTextUpdate"
        >
          <template #header>
            <a id="selectAllJourneys" v-if="journeys.length === 0" @click.prevent="selectAllJourneys">
              <span>Selecionar todas as jornadas</span>
            </a>
            <a id="deselectAllJourneys" v-else @click.prevent="deselectAllJourneys">
              <span>Desmarcar todas as jornadas</span>
            </a>
          </template>
          <template #loading>
            <div class="has-text-centered">
              <p>Carregando...</p>
            </div>
          </template>
          <template #empty>
            <div v-if="!isLoading" class="has-text-centered">Nenhum resultado encontrados</div>
          </template>
        </b-taginput>
      </b-field>
      <b-field label="Branches Existentes" v-if="branchOption === 'useExistingBranch'">
        <b-autocomplete id="useExistingBranch" v-model="branchName" :data="filteredBranchNames" type="text" placeholder="Selecione uma branch existente..." icon="magnifying-glass" :open-on-focus="openOnFocus" clearable>
          <template #loading>
            <div class="has-text-centered">
              <p>Carregando...</p>
            </div>
          </template>
          <template #empty>
            <div v-if="!isLoading" class="has-text-centered">Nenhum resultado encontrados</div>
          </template>
        </b-autocomplete>
      </b-field>
      <div class="mb-5 mt-5">
        <b-field>
          <b-radio-button v-model="branchOption" native-value="createNewBranch" type="is-success is-light is-outlined">
            <b-icon icon="pencil"></b-icon>
            <span>Criar Nova Branch</span>
          </b-radio-button>

          <b-radio-button v-model="branchOption" native-value="useExistingBranch" type="is-info is-light is-outlined">
            <b-icon icon="check"></b-icon>
            <span>Usar Branch Existente</span>
          </b-radio-button>
        </b-field>
      </div>
      <b-field class="is-pulled-left">
        <b-button outlined tag="router-link" type="is-danger" :to="{ name: 'Instance-Journeys' }">Cancelar</b-button>
      </b-field>
      <b-field class="is-pulled-right">
        <b-button id="create-branch-button" type="is-primary" @click="createInstanceJourneyConfig" :loading="isLoading">Criar Instância</b-button>
      </b-field>
    </section>
  </div>
</template>

<script lang="ts">
import { IBranch, IInstanceSettings, SolucxInstance } from '@/models';
import { Vue, Component, Inject, Watch } from 'vue-property-decorator';
import { minLength, required } from 'vuelidate/lib/validators';
import InstanceJourneysService from '@/services/InstanceJourneysService';
import BranchesService from '@/services/BranchesService';
import { showNotification } from '@/utils/notificationUtils';

@Component({
  components: {},
  validations: {
    selectedInstance: { required },
    journeys: { required, minLength: minLength(1) },
  },
})
export default class InstanceJourneyAdd extends Vue {
  @Inject() private instanceJourneysService!: InstanceJourneysService;
  @Inject() private branchesService!: BranchesService;

  public selectedInstance: string = '';
  public branchName: string = '';
  public journeys: string[] = [];
  public isLoading: boolean = false;
  public openOnFocus: boolean = true;
  public branches: IBranch[] = [];
  public instanceJourneyOptions: SolucxInstance[] = [];
  public availableJourneys: string[] = [];
  public searchText: string = '';
  public branchOption: string = '';

  public async mounted(): Promise<void> {
    this.$v.$reset();
    this.branches = await this.branchesService.getBranches();
    await this.fetchInstanceJourneyOptions();
  }

  private async fetchInstanceJourneyOptions(): Promise<void> {
    try {
      this.instanceJourneyOptions = await this.instanceJourneysService.getInstancesJourneysSolucx();
    } catch (error) {
      showNotification(this, 'Erro ao obter as opções de instância e jornada. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  public get filteredInstanceNames(): string[] {
    if (!this.selectedInstance) {
      return this.instanceJourneyOptions.map((option) => option.name);
    }
    const query = this.selectedInstance.toLowerCase();
    return this.instanceJourneyOptions.filter((option) => option.name.toLowerCase().includes(query)).map((option) => option.name);
  }

  public get filteredInstanceJourneys(): string[] {
    return this.availableJourneys.filter((journey) => {
      return journey.toLowerCase().indexOf(this.searchText.toLowerCase()) >= 0 && !this.journeys.includes(journey);
    });
  }

  public handleSearchTextUpdate(value: string): void {
    this.searchText = value;
  }

  public selectAllJourneys(): void {
    this.updateSelectedJourneys([...this.availableJourneys]);
  }

  public deselectAllJourneys(): void {
    this.updateSelectedJourneys([]);
  }

  private updateSelectedJourneys(journeys: string[]): void {
    this.journeys = journeys;
  }

  public get filteredBranchNames(): string[] {
    return this.branches.filter((branch) => branch.name !== null && branch.name.toLowerCase().includes(this.searchText.toLowerCase())).map((branch) => branch.name as string);
  }

  public async createInstanceJourneyConfig(): Promise<void> {
    if (!this.areFieldsValid()) {
      showNotification(this, 'Por favor, preencha todos os campos obrigatórios', 'is-warning', 'is-top', 5000);
      return;
    }

    try {
      const { documentId } = await this.instanceJourneysService.createInstanceJourneysSettings(this.instanceJourneyConfig);
      showNotification(this, 'Instância criada com sucesso', 'is-success', 'is-top', 5000);

      if (documentId) {
        const { routeName, routeParams } = this.getRouteInfo(documentId);
        this.$router.push({ name: routeName, query: routeParams });
      }
    } catch (error) {
      showNotification(this, 'Erro ao criar a instância. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  private areFieldsValid(): boolean {
    this.$v.$touch();
    return !this.$v.$invalid;
  }

  private get instanceJourneyConfig(): IInstanceSettings {
    return {
      instance: this.selectedInstance,
      journeys: this.journeys,
      branchName: this.branchName,
    };
  }

  private getRouteInfo(documentId: string): { routeName: string; routeParams: { instanceId?: string } } {
    const routeName = this.branchOption === 'useExistingBranch' ? 'New-Evaluation' : 'Branch-Add';
    const routeParams = this.branchOption === 'useExistingBranch' ? {} : { instanceId: documentId };

    return { routeName, routeParams };
  }

  @Watch('selectedInstance')
  onInstanceNameChanged(value: string): void {
    this.availableJourneys = [];
    this.journeys = [];

    if (!value) {
      return;
    }

    const selectedInstance = this.instanceJourneyOptions.find((instance) => instance.name === value);
    if (selectedInstance) {
      this.availableJourneys = selectedInstance.journeys;
    }
  }
}
</script>
