import { AxiosError } from 'axios';
import { IAiConfig, IBranch, Params, TagSuggestion } from '@/models';
import BaseService from '@/services/BaseService';

export default class BranchesService extends BaseService {
  public async getBranches(archived?: boolean): Promise<IBranch[]> {
    const params: Params = {
      archived: archived,
    };
    const response = await this.client.get<IBranch[]>('/branches', { params });
    return response.data;
  }

  public async getBranch(documentId: string): Promise<IBranch> {
    const { data } = await this.client.get<IBranch>(`/branches/${documentId}`);
    return data;
  }

  public async getBranchesLogs(documentId: string) {
    try {
      const response = await this.client.get(`/branches/${documentId}/logs`);
      return response.data;
    } catch (error) {
      return (error as AxiosError).response?.status;
    }
  }

  public async createBranchConfig(aiConfig: IAiConfig): Promise<IBranch> {
    const response = await this.client.post('/branches', aiConfig);
    return response.data;
  }

  public async updateBranchConfig(aiConfig: IAiConfig) {
    const { documentId } = aiConfig;
    const response = await this.client.put(`/branches/${documentId}`, aiConfig);
    return response.status;
  }

  public async deleteBranchConfig(documentId: string) {
    try {
      const response = await this.client.delete(`/branches/${documentId}`);
      return response.status;
    } catch (error) {
      return (error as AxiosError).response?.status;
    }
  }

  public async getTagSuggestions(instance: string, journeys: string[]): Promise<string[]> {
    const task = this.createTaskObject(instance, journeys);
    const { data } = await this.client.post<TagSuggestion[]>(`/branches/suggest-tags`, task);
    return this.processTagSuggestions(data);
  }

  private createTaskObject(instance: string, journeys: string[]) {
    const thirtyDaysAgo = new Date(new Date().setDate(new Date().getDate() - 30));
    return {
      instance,
      journeys,
      endDate: new Date(),
      startDate: thirtyDaysAgo,
      limit: 100,
      ignoreStatus: true,
    };
  }

  private processTagSuggestions(data: TagSuggestion[]): string[] {
    const uniqueTags = new Set<string>();
    const resultTags: string[] = [];

    data.forEach(({ tag, subtag }) => {
      const tagCombo = `${tag} - ${subtag}`;
      if (!uniqueTags.has(tag)) {
        uniqueTags.add(tag);
        resultTags.push(tag);
      }
      if (!uniqueTags.has(tagCombo)) {
        uniqueTags.add(tagCombo);
        resultTags.push(tagCombo);
      }
    });

    resultTags.sort((a, b) => a.localeCompare(b));

    return resultTags;
  }
}
