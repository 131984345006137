<template>
  <div class="container is-max-desktop">
    <div class="columns">
      <div class="column content">
        <h2>Performance</h2>
        <DateRangePicker :selectedDateRange="selectedDateRange" @dateRangeChange="handleDateRangeChange" />
      </div>
      <div class="column content is-flex is-justify-content-right">
        <DownloadCsvButton :documentId="documentId" :startDate="startDate" :endDate="endDate" />
      </div>
    </div>
    <div>
      <b-table :loading="isLoading" :data="tagsPerformanceStatistics">
        <b-table-column label="Tag" v-slot="{ row }"> {{ row.tag }} <b-tag icon="sparkles" type="is-info is-small" v-if="row.type === 'new'">New</b-tag> </b-table-column>
        <b-table-column label="Total" :centered="true" v-slot="{ row }">
          {{ row.total }}
        </b-table-column>
        <b-table-column label="Distribuição" centered v-slot="{ row }">
          <StatsBarComponent :max="maxTotal" :negatives="row.negatives" :neutrals="row.neutrals" :positives="row.positives" />
        </b-table-column>
        <b-table-column v-slot="{ row }" fiedl="actions" centered label="Ações">
          <b-dropdown v-model="showActionsDropdown" position="is-bottom-left" trigger="is-dark">
            <b-button class="button is-small" type=" is-light" slot="trigger">
              <b-icon icon="fas fa-ellipsis-v"></b-icon>
            </b-button>
            <b-dropdown-item id="add-new-tag" :disabled="row.type !== 'new' || !canAddTagOnBranch" @click="addNewTagOnBrach(row.tag, row.instance)">
              <b-icon icon="plus" type="is-primary" outlined></b-icon>
              <span> Adicionar Tag </span>
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>

        <template #loading>
          <div class="has-text-centered">
            <p id="loading">Carregando...</p>
          </div>
        </template>
        <template #empty>
          <div id="empty" v-if="!isLoading" class="has-text-centered">Nenhum resultado encontrado</div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Vue } from 'vue-property-decorator';
import StatsBarComponent from '@/app/Components/Commons/StatsBarComponent.vue';
import DateRangePicker from '@/app/Components/InstancesAndJourneys/DateRangePicker.vue';
import InstanceJourneysService from '@/services/InstanceJourneysService';
import { IEvaluationResults, ITagStatistics } from '@/models';
import { showNotification } from '@/utils/notificationUtils';
import DownloadCsvButton from '@/app/Components/InstancesAndJourneys/DownloadCsvButton.vue';
import { hasManagerOrAdminPermissionForTool } from '@/utils/validateUserUtils';

@Component({ components: { StatsBarComponent, DateRangePicker, DownloadCsvButton } })
export default class InstanceJourneyStats extends Vue {
  @Inject() private instanceJourneysService!: InstanceJourneysService;

  public evaluationResults: IEvaluationResults | null = null;
  public selectedDateRange: Array<Date> = this.getDefaultDateRange();
  public startDate: string = '';
  public endDate: string = '';
  public tagsPerformanceStatistics: Array<ITagStatistics> = [];
  public isLoading: boolean = false;
  public showActionsDropdown: boolean = true;

  public mounted(): void {
    this.startDate = this.formatDateToYYYYMMDD(this.selectedDateRange[0]);
    this.endDate = this.formatDateToYYYYMMDD(this.selectedDateRange[1]);
    this.handleGetTagsPerformance(this.documentId, this.startDate, this.endDate);
  }

  private formatDateToYYYYMMDD(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  public get documentId(): string {
    return this.$route.params.id;
  }

  private getDefaultDateRange(): Array<Date> {
    const today = new Date();
    const ninetyDaysAgo = this.subtractDays(today, 90);
    return [ninetyDaysAgo, today];
  }

  private subtractDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  }

  public get maxTotal(): number {
    if (!this.tagsPerformanceStatistics?.length) {
      return 0;
    }
    return this.tagsPerformanceStatistics.reduce((max, p) => (p.total > max ? p.total : max), 0);
  }

  public handleDateRangeChange([startDate, endDate]: [string, string]): void {
    this.startDate = startDate;
    this.endDate = endDate;

    this.handleGetTagsPerformance(this.documentId, startDate, endDate);
  }

  public async handleGetTagsPerformance(documentId: string, startDate: string, endDate: string): Promise<void> {
    try {
      this.isLoading = true;
      const response = await this.instanceJourneysService.getTagsPerformance(documentId, startDate, endDate);
      this.tagsPerformanceStatistics = response;
    } catch (error) {
      showNotification(this, 'Erro ao carregar a performance das tags', 'is-warning', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public addNewTagOnBrach(tagName: string, instance: string): void {
    this.$buefy.dialog.confirm({
      title: 'Atenção!',
      confirmText: 'Confirmar',
      cancelText: 'Cancelar',
      type: 'is-warning',
      message: `<p>Deseja adicionar a tag <b>"${tagName}"</b> à instância <b>"${instance}"</b>?</p>
<p>Ao fazer isso, você estará alterando as configurações da branch utilizada para classificar os comentários, e impactando diretamente nas classificações de todas as instâncias que utilizarem essa branch. Tem certeza que deseja continuar?</p>`,
      onConfirm: () => this.handleAddNewTagOnTheBranch(tagName),
    });
  }

  public async handleAddNewTagOnTheBranch(tagName: string): Promise<void> {
    try {
      await this.instanceJourneysService.addNewTagOnTheBranch(this.documentId, tagName);
      showNotification(this, 'Nova tag adicionada com sucesso', 'is-success', 'is-top', 5000);
      this.handleGetTagsPerformance(this.documentId, this.startDate, this.endDate);
    } catch (error) {
      showNotification(this, 'Erro ao adicionar nova tag', 'is-warning', 'is-top', 5000);
    }
  }

  public get canAddTagOnBranch(): boolean {
    return hasManagerOrAdminPermissionForTool('ai-tag-manager');
  }

  public get evaluatedDate(): string {
    return this.evaluationResults?.evaluatedAt ?? '';
  }

  public getTagStyle(sentiment: string) {
    switch (sentiment) {
      case 'positive':
        return { icon: 'thumbs-up', type: 'is-success is-light' };
      case 'negative':
        return { icon: 'thumbs-down', type: 'is-danger is-light' };
      case 'neutral':
        return { icon: 'thumbs-info', type: 'is-warning is-light' };
      default:
        return {};
    }
  }
}
</script>
