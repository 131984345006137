<template>
  <b-step-item step="3" label="Validação dos Comentários" :clickable="false">
    <div class="section">
      <div>Total de comentários importados: {{ importedComments ? importedComments.length : 0 }}</div>
      <div class="container">
        <b-table :data="importedComments" :loading="isLoading">
          <b-table-column field="Comentários" label="Comentário" v-slot="{ row }">
            <div class="content">
              <blockquote>{{ row.text }}</blockquote>
            </div>
          </b-table-column>
          <template #loading>
            <div class="has-text-centered">
              <p>Carregando...</p>
            </div>
          </template>
          <template #empty>
            <div v-if="!isLoading" class="has-text-centered">Sem Comentários Importados</div>
          </template>
        </b-table>
        <div class="column is-flex is-justify-content-space-between mt-3">
          <b-button id="delete-button" type="is-danger" outlined icon-left="rotate" @click="handleDeleteImportedComment">Descartar e importar outros comentários</b-button>
          <b-button id="continue-button" :disabled="importedComments.length === 0" type="is-primary" icon-left="forward" @click="$emit('nextStep')">Continuar</b-button>
        </div>
      </div>
    </div>
  </b-step-item>
</template>

<script lang="ts">
import { IEvaluationComment } from '@/models';
import { showNotification } from '@/utils/notificationUtils';
import { Vue, Component, Prop, Inject, Watch } from 'vue-property-decorator';
import EvaluationService from '@/services/EvaluationService';

@Component({ components: {} })
export default class EvaluationCommentsStep extends Vue {
  @Inject() private evaluationService!: EvaluationService;
  @Prop() public readonly comments!: IEvaluationComment[];
  @Prop() public readonly evaluationId!: string;

  public isLoading: boolean = false;
  public importedComments: IEvaluationComment[] = [];

  public mounted(): void {
    this.importedComments = this.comments;
  }

  public async handleDeleteImportedComment(): Promise<void> {
    try {
      this.isLoading = true;
      await this.evaluationService.deleteImportedComments(this.evaluationId);
      showNotification(this, 'Comentários deletados com sucesso', 'is-success', 'is-top', 5000);
      await this.handleGetImportedComments();
      this.$emit('previousStep');
    } catch (error) {
      showNotification(this, 'Não foi possível deletar os comentários. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public async handleGetImportedComments(): Promise<void> {
    try {
      this.importedComments = await this.evaluationService.getEvaluationImportedComments(this.evaluationId);
    } catch (error) {
      showNotification(this, 'Não foi possível obter os comentários importados. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  @Watch('comments')
  public onCommentsChange(newComments: IEvaluationComment[]): void {
    this.importedComments = [...newComments];
  }
}
</script>
