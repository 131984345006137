<template>
  <b-button id="download-csv-button" icon-left="file-csv" type="is-primary" @click="handleSubmitDownloadResultsToCSV()">Baixar CSV</b-button>
</template>

<script lang="ts">
import { Vue, Prop, Inject, Component } from 'vue-property-decorator';
import InstanceJourneysService from '@/services/InstanceJourneysService';
import { showNotification } from '@/utils/notificationUtils';

@Component({ components: {} })
export default class DownloadButton extends Vue {
  @Inject() private instanceJourneysService!: InstanceJourneysService;

  @Prop({ default: '', required: true }) public readonly documentId!: string;
  @Prop({ default: '', required: true }) public readonly startDate!: string;
  @Prop({ default: '', required: true }) public readonly endDate!: string;

  public async handleSubmitDownloadResultsToCSV() {
    try {
      const response = await this.instanceJourneysService.downloadEvaluationResultsToCSV(this.documentId, this.startDate, this.endDate);
      if (response === undefined) {
        throw new Error('Response is undefined');
      }
      if (typeof response === 'number') {
        throw new Error(`Error status: ${response}`);
      }
      const downloadUrl = this.createDownloadBlob(response.data, 'text/csv');
      const archiveName = response.headers['content-disposition'].split('filename=')[1];
      this.triggerDownload(downloadUrl, archiveName);
    } catch (error) {
      showNotification(this, 'Erro ao baixar os resultados da avaliação', 'is-warning', 'is-top', 5000);
    }
  }

  private createDownloadBlob(data: string, type: string): string {
    const blob = new Blob([data], { type });
    return URL.createObjectURL(blob);
  }

  private triggerDownload(url: string, filename: string): void {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
</script>
