import axios, { AxiosInstance } from 'axios';
import BaseService from '@/services/BaseService';
import BranchesService from '@/services/BranchesService';
import EvaluationService from '@/services/EvaluationService';
import InstanceJourneysService from '@/services/InstanceJourneysService';

export default class ServicesInitializer {
  private readonly client: AxiosInstance;

  constructor() {
    this.client = this.createClient();
  }

  public getServices(userToken: string): Record<string, BaseService> {
    return {
      branchesService: new BranchesService(this.client, userToken),
      evaluationService: new EvaluationService(this.client, userToken),
      instanceJourneysService: new InstanceJourneysService(this.client, userToken),
    };
  }

  private createClient(): AxiosInstance {
    const baseURL = process.env.VUE_APP_BACKEND_URL;
    return axios.create({ baseURL });
  }
}
