<template>
  <div class="container">
    <div class="my-4">
      <b-field label="Instância">
        <b-select placeholder="Selecione uma instância..." id="instance-select" v-model="selectedInstance" @input="onInstanceSelected" expanded>
          <option v-for="(option, index) in instanceOptions" :key="index" :value="option.value">
            {{ option.label }}
          </option>
        </b-select>
      </b-field>
    </div>
    <div class="my-4">
      <b-field label="Jornadas" expanded>
        <b-taginput id="journey-select" v-model="journeys" :disabled="isDisabled" type="is-success" placeholder="Selecione uma jornada..." aria-close-label="Delete this tag"> </b-taginput>
      </b-field>
    </div>
    <div class="my-4">
      <b-field label="Nome da Branch" expanded>
        <b-input id="branch-select" v-model="branchName" :disabled="isDisabled" placeholder="Selecione uma branch..."></b-input>
      </b-field>
    </div>
    <div class="my-4">
      <b-field label="Selecione um intervalo de data">
        <b-datepicker id="date-select" icon="calendar" v-model="selectDate" placeholder="Selecione um intervalo..." range />
      </b-field>
    </div>
    <div class="my-4">
      <b-field label="Quantidade de Comentários">
        <b-numberinput id="comments-select" v-model="totalComments" :controls="controls" controls-rounded controls-alignment="right" min="1" max="500"></b-numberinput>
      </b-field>
    </div>
    <div class="my-4 is-flex">
      <b-switch id="ignore-status" v-model="ignoreStatus" class="mr-4">
        <span>Ignorar status</span>
      </b-switch>
      <b-switch id="waiting-classification" v-model="includeWaitingForClassification" class="mr-4">
        <span>Aguardando classificação</span>
      </b-switch>
      <b-tooltip position="is-bottom" type="is-light" multilined>
        <i class="fa-solid fa-circle-info"></i>
        <template v-slot:content>
          <div class="my-2 has-text-left">
            <ul>
              <li>
                <strong>Ignorar Status:</strong> Classifica todos os comentários dentro do período selecionado, independentemente do <strong>status PLN</strong>, atenção pois esta ação pode gerar um
                <strong>alto custo</strong> , pois irá classificar novamente até os comentários que já foram classificados.
              </li>
            </ul>
          </div>
          <div class="my-2 has-text-left">
            <ul>
              <li><strong>Aguardando Classificação:</strong> Inclui também comentários que estão aguardando classificação, forçando a classificação no período definido, <strong>status PLN 0 e 1</strong>.</li>
            </ul>
          </div>
        </template>
      </b-tooltip>
    </div>
    <div class="my-4 has-text-right">
      <b-button id="force-classification" type="is-primary" :loading="isLoading" :disabled="!hasAllRequiredFields" @click="openModalFromForceClassification(instanceConfig)"><span>Forçar Classificação</span></b-button>
    </div>
  </div>
</template>
<script lang="ts">
import { IEvaluationConfig, IInstanceSettings } from '@/models';
import EvaluationService from '@/services/EvaluationService';
import InstanceJourneysService from '@/services/InstanceJourneysService';
import { showNotification } from '@/utils/notificationUtils';
import { Vue, Component, Inject } from 'vue-property-decorator';

@Component({ components: {} })
export default class ForceClassification extends Vue {
  @Inject() private instanceJourneysService!: InstanceJourneysService;
  @Inject() private evaluationService!: EvaluationService;

  public selectedInstance: string | null = null;
  public selectedInstanceName: string = '';
  public journeys: string[] = [];
  public branchName: string = '';
  public documentId: string = '';
  public instanceId: string = '';
  public instanceSettings: IInstanceSettings | null = null;
  public instancesJourneysOptions: IInstanceSettings[] = [];
  public selectDate: Date[] | null = null;
  public totalComments: number = 1;
  public isDisabled: boolean = false;
  public ignoreStatus: boolean = false;
  public includeWaitingForClassification: boolean = false;
  public openOnFocus: boolean = true;
  public controls: boolean = false;
  public isLoading: boolean = false;

  public async mounted(): Promise<void> {
    await this.handleGetInstancesJourneys();
  }

  public async handleGetInstancesJourneys(): Promise<void> {
    try {
      this.instancesJourneysOptions = await this.instanceJourneysService.getInstanceJourneysSettings();
    } catch (error) {
      showNotification(this, 'Não foi possível obter a lista de instâncias/jornadas. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  public get instanceOptions(): { label: string; value: string }[] {
    return this.instancesJourneysOptions
      .filter((item) => item.documentId)
      .map((item) => ({
        label: `${item.instance}`,
        value: item.documentId || '',
      }));
  }

  public get hasAllRequiredFields(): boolean {
    return this.selectedInstance !== null && this.journeys.length > 0 && this.branchName !== '' && this.selectDate !== null && this.totalComments >= 1;
  }

  public onInstanceSelected(): void {
    const selectedInstanceObject = this.instancesJourneysOptions.find((item) => item.documentId === this.selectedInstance);
    if (selectedInstanceObject) {
      this.selectedInstanceName = selectedInstanceObject.instance as string;
      this.instanceId = selectedInstanceObject.documentId as string;
      this.journeys = [...selectedInstanceObject.journeys];
      this.branchName = selectedInstanceObject.branchName as string;
      this.isDisabled = true;
    } else {
      this.journeys = [];
      this.branchName = '';
    }
  }

  public formatStartDate(date: Date): string {
    return date.toISOString().split('T')[0] + 'T00:00:00.000Z';
  }

  public formatEndDate(date: Date): string {
    return date.toISOString().split('T')[0] + 'T23:59:59.999Z';
  }

  public get instanceConfig(): IEvaluationConfig {
    return {
      instanceId: this.instanceId,
      instance: this.selectedInstanceName,
      journeys: this.journeys,
      startDate: this.selectDate ? this.formatStartDate(this.selectDate[0]) : '',
      endDate: this.selectDate ? this.formatEndDate(this.selectDate[1]) : '',
      limit: this.totalComments,
      ignoreStatus: this.ignoreStatus,
      includeWaitingForClassification: this.includeWaitingForClassification,
    };
  }

  public openModalFromForceClassification(instanceConfig: IEvaluationConfig): void {
    this.$buefy.dialog.confirm({
      title: 'Atenção!',
      confirmText: 'Prosseguir',
      cancelText: 'Cancelar',
      type: 'is-warning',
      message: 'Essa ação tem <strong>custos</strong> e impacta <strong>diretamente o cliente</strong>. Você está ciente e quer realmente prosseguir?',
      onConfirm: () => this.handleForceClassification(instanceConfig),
    });
  }

  public async handleForceClassification(instanceConfig: IEvaluationConfig) {
    this.isLoading = true;
    try {
      const { classifiedCount } = await this.evaluationService.forceClassification(instanceConfig);
      if (classifiedCount === 0) {
        showNotification(this, 'Nenhum comentário encontrado com os filtros fornecidos.', 'is-warning', 'is-top', 8000);
        return;
      }
      showNotification(this, `Classificação forçada com sucesso! Total de comentários enviados pra classificação: ${classifiedCount}`, 'is-success', 'is-top', 8000);
      this.resetData();
    } catch (error) {
      console.error('Erro ao enviar classificação:', error);
      showNotification(this, 'Falha ao forçar classificação.', 'is-danger', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public resetData(): void {
    this.selectedInstance = null;
    this.selectedInstanceName = '';
    this.journeys = [];
    this.branchName = '';
    this.selectDate = null;
    this.totalComments = 1;
    this.isDisabled = false;
    this.ignoreStatus = false;
    this.includeWaitingForClassification = false;
  }
}
</script>
