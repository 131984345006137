<template>
  <div id="evaluation-config-step">
    <b-step-item step="2" label="Configurações da Importação" :clickable="false">
      <div class="section">
        <div class="columns">
          <div class="column">
            <b-field label="Instância">
              <b-select id="instance-select" v-model="selectedInstance" @input="onInstanceSelected" expanded>
                <option v-for="(option, index) in instanceOptions" :key="index" :value="option.value">
                  {{ option.label }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Jornadas" expanded>
              <b-taginput v-model="selectedJourneys" :data="selectedInstanceJourneys" :open-on-focus="openOnFocus" type="is-info is-light">
                <template #header>
                  <a id="selectAllJourneys" v-if="selectedJourneys.length === 0" @click.prevent="selectAllJourneys">
                    <span>Selecionar todas as jornadas</span>
                  </a>
                  <a id="deselectAllJourneys" v-else @click.prevent="deselectAllJourneys">
                    <span>Desmarcar todas as jornadas</span>
                  </a>
                </template>
              </b-taginput>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <b-field label="Data inicial">
              <b-datepicker v-model="startDate" :max-date="endDate" @input="updateEndDateMinDate"> </b-datepicker>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="Data final">
              <b-datepicker v-model="endDate" :min-date="startDate" @input="updateStartDateMaxDate"> </b-datepicker>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field label="Quantidade de comentários">
              <b-numberinput v-model="totalComments" :controls="controls" min="10" max="200"> </b-numberinput>
            </b-field>
          </div>
        </div>
        <div class="is-flex is-justify-content-flex-end">
          <b-button id="import-button" :disabled="!isAreFieldsValid" type="is-primary" :loading="isLoading" icon-left="file-import" @click="handleNewimportComments(evaluationConfig)">Importar</b-button>
        </div>
      </div>
    </b-step-item>
  </div>
</template>

<script lang="ts">
import { IInstanceSettings, IEvaluationConfig, IEvaluationComment } from '@/models';
import EvaluationService from '@/services/EvaluationService';
import InstanceJourneysService from '@/services/InstanceJourneysService';
import { showNotification } from '@/utils/notificationUtils';
import { Vue, Component, Inject, Watch, Prop } from 'vue-property-decorator';

@Component({ components: {} })
export default class EvaluationConfigStep extends Vue {
  @Inject() private instanceJourneysService!: InstanceJourneysService;
  @Inject() private evaluationService!: EvaluationService;
  @Prop() private readonly evaluationId!: string;

  public selectedInstance: string = '';
  public selectedInstanceName: string = '';
  public instanceId: string = '';
  public instanceSettings: IInstanceSettings | null = null;
  public instancesJourneysOptions: IInstanceSettings[] = [];
  public selectedJourneys: string[] = [];
  public startDate: Date = new Date(new Date().setDate(new Date().getDate() - 30));
  public endDate: Date = new Date();
  public totalComments: number = 10;
  public comments: IEvaluationComment[] = [];

  public openOnFocus: boolean = true;
  public controls: boolean = false;
  public isLoading: boolean = false;

  public async mounted(): Promise<void> {
    await this.handleGetInstancesJourneys();
    await this.loadInstanceSettings();
  }

  public get evaluationConfig(): IEvaluationConfig {
    return {
      documentId: this.evaluationId,
      instance: this.selectedInstanceName,
      instanceId: this.instanceId,
      journeys: this.selectedJourneys,
      startDate: this.formatDateToYYYYMMDD(this.startDate),
      endDate: this.formatDateToYYYYMMDD(this.endDate),
      limit: this.totalComments,
    };
  }

  private formatDateToYYYYMMDD(inputDate: Date): string {
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
    const day = inputDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  public async handleGetInstancesJourneys(): Promise<void> {
    try {
      this.instancesJourneysOptions = await this.instanceJourneysService.getInstanceJourneysSettings();
    } catch (error) {
      showNotification(this, 'Não foi possível obter a lista de instâncias/jornadas. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  private async loadInstanceSettings(): Promise<void> {
    const instanceId = this.$route.query.instanceId as string;
    if (instanceId) {
      this.instanceSettings = await this.instanceJourneysService.getInstanceJourneysSetting(instanceId);
    }
  }

  public get instanceOptions(): { label: string; value: string }[] {
    return this.instancesJourneysOptions
      .filter((item) => item.documentId)
      .map((item) => ({
        label: `${item.instance}`,
        value: item.documentId || '',
      }));
  }

  public onInstanceSelected(): void {
    const selectedInstanceObject = this.instancesJourneysOptions.find((item) => item.documentId === this.selectedInstance);
    if (selectedInstanceObject) {
      this.selectedInstanceName = selectedInstanceObject.instance as string;
      this.instanceId = selectedInstanceObject.documentId as string;
    }
  }

  public get selectedInstanceJourneys(): string[] {
    const instance = this.instancesJourneysOptions.find((item) => item.documentId === this.selectedInstance);
    return instance ? instance.journeys : [];
  }

  public selectAllJourneys(): void {
    this.selectedJourneys = [...this.selectedInstanceJourneys];
  }

  public deselectAllJourneys(): void {
    this.selectedJourneys = [];
  }

  public get isAreFieldsValid(): boolean {
    return this.selectedInstanceName !== '' && this.selectedJourneys.length > 0 && this.totalComments >= 10;
  }

  public updateEndDateMinDate(value: Date): void {
    this.endDate = value > this.endDate ? value : this.endDate;
  }

  public updateStartDateMaxDate(value: Date): void {
    this.startDate = value < this.startDate ? value : this.startDate;
  }

  public async handleNewimportComments(evaluationConfig: IEvaluationConfig): Promise<void> {
    try {
      this.isLoading = true;
      await this.evaluationService.importComments(evaluationConfig);
      await this.handleGetImportedComments();
      this.$emit('importedComments', { comments: this.comments });
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.response && error.response.status == 400) {
        showNotification(this, 'Não existem comentários a serem importados com o filtro atual.', 'is-warning', 'is-top', 5000);
      } else {
        showNotification(this, 'Não foi possível importar os comentários. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
      }
    } finally {
      this.isLoading = false;
    }
  }

  public async handleGetImportedComments(): Promise<void> {
    try {
      this.comments = await this.evaluationService.getEvaluationImportedComments(this.evaluationId);
    } catch (error) {
      showNotification(this, 'Não foi possível obter os comentários importados. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  // WATCHES
  @Watch('selectedInstance')
  onSelectedInstanceChange(newValue: string, oldValue: string) {
    if (newValue !== oldValue && !this.instanceSettings?.journeys) {
      this.selectedJourneys = [];
    }
  }
}
</script>
