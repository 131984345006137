<template>
  <b-progress size="is-medium" class="is-flex is-flex-direction-row-reverse" :rounded="false" type="is-success" show-value :max="max" format="percent" :precision="1">
    <template #bar>
      <b-progress-bar :value="negatives" v-if="negatives" type="is-danger" />
      <b-progress-bar :value="neutrals" v-if="neutrals" type="is-warning" />
      <b-progress-bar :value="positives" v-if="positives" type="is-success" />
    </template>
  </b-progress>
</template>

<script lang="ts">
import { Vue, Prop, Component } from 'vue-property-decorator';

@Component({ components: {} })
export default class StatsBarComponent extends Vue {
  @Prop({ required: true }) max!: number;
  @Prop({ required: false, default: 0 }) negatives!: number;
  @Prop({ required: false, default: 0 }) neutrals!: number;
  @Prop({ required: false, default: 0 }) positives!: number;
}
</script>
