<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    <header class="content">
      <h1>Editar Branch</h1>
    </header>
    <section>
      <div class="columns">
        <div class="column">
          <b-field label="Nome" :type="{ 'is-danger': !isFieldValid('promptName') }" :message="!isFieldValid('promptName') ? 'O nome da branch é obrigatório' : ''">
            <b-input v-model="promptName"></b-input>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Descrição">
            <b-input v-model="promptDescription"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Tags">
            <b-taginput v-model="tags" type="is-primary is-light" aria-close-label="Delete this tag"> </b-taginput>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Serviço de classificação">
            <b-select v-model="serviceOptionSelected" placeholder="Selecione um serviço para realizar a classificação" expanded>
              <option v-for="serviceOption in aiServiceOptions" :key="serviceOption.value" :value="serviceOption.value">
                {{ serviceOption.label }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Configuração do serviço" :type="{ 'is-danger': !isFieldValid('aiServiceSelected') }" :message="!isFieldValid('aiServiceSelected') ? 'A seleção de serviço AI é obrigatória' : ''">
            <b-select v-model="aiServiceSelected" placeholder="Configure seu serviço de classificação" expanded>
              {{ aiServiceConfig }}
              <option v-for="serviceConfig in aiServiceConfig" :key="serviceConfig.value" :value="serviceConfig.value">
                {{ serviceConfig.label }}
              </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Prompt de contexto" :type="{ 'is-danger': !isFieldValid('promptSys') }" :message="!isFieldValid('promptSys') ? 'O prompt de contexto é obrigatório!' : ''">
            <textarea v-model="promptSys" :maxlength="maxPromptSysLength" :class="{ textarea: true, 'is-danger': !isFieldValid('promptSys') }" rows="5"></textarea>
          </b-field>
          <b-tag type="is-warning">{{ promptSysRemainingChars }} caracteres restantes</b-tag>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Prompt de usuário" :type="{ 'is-danger': !isFieldValid('promptUser') }" :message="!isFieldValid('promptUser') ? 'O prompt do usuário é obrigatório' : ''">
            <textarea v-model="promptUser" :maxlength="maxPromptUserLength" :class="{ textarea: true, 'is-danger': !isFieldValid('promptUser') }" rows="5"></textarea>
          </b-field>
          <b-tag type="is-warning"> {{ promptUserRemainingChars }} caracteres restantes</b-tag>
        </div>
      </div>
    </section>
    <footer v-if="checkIfIsNotUser" class="has-text-right">
      <b-button type="is-success" @click="handleUpdateBranchConfig(aiConfig)" :loading="isLoading">Atualizar Branch</b-button>
    </footer>
  </div>
</template>

<script lang="ts">
import { OpenAiSettings, IAiConfig, AIServicesEnum, ISelectableOption, IBranch } from '@/models';
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';
import { showNotification, handleResponse, updateBranchResponseMap } from '@/utils/notificationUtils';
import BranchesService from '@/services/BranchesService';
import { AxiosError } from 'axios';

@Component({
  components: {},
  validations: {
    promptName: { required },
    promptUser: { required },
    promptSys: { required },
    aiServiceSelected: { required },
  },
})
export default class BranchesEdit extends Vue {
  @Inject() private branchesService!: BranchesService;

  @Prop() public readonly userRole!: string;
  public promptName: string = '';
  public promptDescription: string = '';
  public tags: string[] = [];
  public promptUser: string = '';
  public promptSys: string = '';
  public aiServiceSelected: string = '';
  public serviceOptionSelected: AIServicesEnum | null | undefined | string = null;
  public documentId: string = '';

  public aiServiceConfig: ISelectableOption[] = [];
  public aiServiceOptions: ISelectableOption[] = [];

  public maxPromptSysLength: number = 1800;
  public maxPromptUserLength: number = 1800;
  public branch: IBranch | null = null;

  public isLoading: boolean = false;

  public async mounted(): Promise<void> {
    this.documentId = this.$route.params.id;
    await this.loadBranch(this.documentId);
    if (this.branch) {
      this.initializeBranchData(this.branch);
    }
    this.initializeAiServiceOptions();
    this.initializeAiServiceConfig();
  }

  public async loadBranch(documentId: string): Promise<void> {
    try {
      this.isLoading = true;
      const branch = await this.branchesService.getBranch(documentId);
      this.branch = branch;
    } catch (error) {
      showNotification(this, 'Erro ao obter as configurações da branch. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public initializeBranchData(data: IBranch): void {
    this.promptName = data.name ?? '';
    this.promptDescription = data.description ?? '';
    this.tags = data.tags ?? [];
    if (data.aiServiceSettings?.openAi) {
      this.aiServiceSelected = data.aiServiceSettings.openAi.model ?? '';
      this.promptSys = data.aiServiceSettings.openAi.promptSys;
      this.promptUser = data.aiServiceSettings.openAi.promptUser;
    }
    this.serviceOptionSelected = data.aiDefaultService;
    this.documentId = data.documentId;
  }

  public initializeAiServiceOptions(): void {
    this.aiServiceOptions = [{ value: 'openAi', label: 'OpenAI' }];
  }

  public initializeAiServiceConfig(): void {
    this.aiServiceConfig = [
      { value: 'gpt-3.5-turbo', label: 'GPT 3.5 Turbo' },
      { value: 'gpt-4-1106-preview', label: 'GPT 4 Turbo' },
      { value: 'gpt-4o', label: 'GPT 4 Omni' },
      { value: 'gpt-4o-mini', label: 'GPT 4o Mini' },
    ];
  }

  public get aiConfig(): IAiConfig {
    return {
      documentId: this.documentId,
      name: this.promptName,
      description: this.promptDescription,
      tags: this.tags,
      aiDefaultService: this.selectedService,
      aiServiceSettings: { openAi: this.openAiSettings },
    };
  }

  public get selectedService(): AIServicesEnum {
    return this.serviceOptionSelected as unknown as AIServicesEnum;
  }

  public get openAiSettings(): OpenAiSettings {
    const openAiConfig = this.branch?.aiServiceSettings?.openAi;

    return {
      model: this.aiServiceSelected,
      promptSys: this.promptSys,
      promptUser: this.promptUser,
      parser: openAiConfig?.parser,
      temperature: openAiConfig?.temperature,
      presence_penalty: openAiConfig?.presence_penalty,
      frequency_penalty: openAiConfig?.frequency_penalty,
      top_p: openAiConfig?.top_p,
      positivesHeaders: openAiConfig?.positivesHeaders,
      negativeHeaders: openAiConfig?.negativeHeaders,
      neutralHeaders: openAiConfig?.neutralHeaders,
      positivesRegex: openAiConfig?.positivesRegex,
      negativeRegex: openAiConfig?.negativeRegex,
      neutralRegex: openAiConfig?.neutralRegex,
    };
  }

  public get checkIfIsNotUser(): boolean {
    return this.userRole !== 'user';
  }

  public get validationFieldsState() {
    return {
      isPromptNameValid: !this.$v.promptName.$invalid,
      isPromptUserValid: !this.$v.promptUser.$invalid,
      isPromptSysValid: !this.$v.promptSys.$invalid,
      isAiServiceValid: !this.$v.aiServiceSelected.$invalid,
    };
  }

  get promptSysRemainingChars(): number {
    return this.promptSys ? this.maxPromptSysLength - this.promptSys.length : this.maxPromptSysLength;
  }

  get promptUserRemainingChars(): number {
    return this.promptUser ? this.maxPromptUserLength - this.promptUser.length : this.maxPromptUserLength;
  }

  public isFieldValid(fieldName: string): boolean {
    return !this.$v[fieldName].$invalid;
  }

  public async handleUpdateBranchConfig(aiConfig: IAiConfig): Promise<void> {
    try {
      this.isLoading = true;
      await this.branchesService.updateBranchConfig(aiConfig);
      handleResponse(this, 200, updateBranchResponseMap);
      this.navigateToBranches();
    } catch (error) {
      if (error instanceof AxiosError) {
        handleResponse(this, error.response?.data.statusCode, updateBranchResponseMap);
      }
    } finally {
      this.isLoading = false;
    }
  }

  public navigateToBranches(): void {
    this.$router.push({ name: 'Branches' });
  }
}
</script>
