<template>
  <div>
    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    <header class="content">
      <h1>Editar Instância</h1>
    </header>
    <section>
      <div class="columns">
        <div class="column">
          <b-field label="Nome da instância" :type="{ 'is-danger': $v.instanceName.$error }" :message="$v.instanceName.$error ? 'O nome da branch é obrigatório' : ''">
            <b-input v-model="instanceName" type="text"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Branch" :type="{ 'is-danger': $v.branchName.$error }" :message="$v.branchName.$error ? 'O nome da branch é obrigatório' : ''">
            <b-input v-model="branchName"></b-input>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field label="Jornadas" :type="{ 'is-danger': $v.journeys.$error }" :message="$v.journeys.$error ? 'O nome da branch é obrigatório' : ''">
            <b-taginput v-model="journeys" type="is-success" aria-close-label="Delete this tag"> </b-taginput>
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <b-field>
            <b-tooltip label="Somente administradores podem ligar ou desligar a classificação" type="is-light" animated v-if="!isUserAdmin">
              <b-switch :disabled="!isUserAdmin" type="is-success" v-model="isSchedulingOn">Ligar classificação</b-switch>
            </b-tooltip>
            <b-switch :disabled="!isUserAdmin" type="is-success" v-model="isSchedulingOn" v-else>Ligar classificação</b-switch>
          </b-field>
        </div>
      </div>
    </section>
    <footer class="has-text-right">
      <b-tooltip label="Somente administradores ou gerentes podem editar as configurações de uma instância/jornada" type="is-light" animated v-if="!isUserManagerOrAdmin">
        <b-button :disabled="!isUserManagerOrAdmin" type="is-success" @click="handleUpdateInstanceJourneyConfig()" :loading="isLoading">Atualizar Instância</b-button>
      </b-tooltip>
      <b-button type="is-success" @click="handleUpdateInstanceJourneyConfig()" :loading="isLoading" v-else>Atualizar Instância</b-button>
    </footer>
  </div>
</template>

<script lang="ts">
import { IInstanceSettings } from '@/models';
import { Vue, Component, Prop, Inject } from 'vue-property-decorator';
import { hasManagerOrAdminPermissionForTool, isAdminForTool } from '@/utils/validateUserUtils';
import { minLength, required } from 'vuelidate/lib/validators';
import { showNotification, handleResponse, instanceJourneysServiceresponseMap } from '@/utils/notificationUtils';
import InstanceJourneysService from '@/services/InstanceJourneysService';
import { AxiosError } from 'axios';

@Component({
  components: {},
  validations: {
    instanceName: { required },
    branchName: { required },
    journeys: {
      required,
      minLength: minLength(1),
    },
  },
})
export default class InstanceJourneyEdit extends Vue {
  @Inject() private instanceJourneysService!: InstanceJourneysService;

  @Prop() public readonly userRole!: string;
  public instanceName: string = '';
  public branchName: string = '';
  public journeys: string[] = [];
  public documentId: string = '';
  public isSchedulingOn: boolean = false;
  public instanceJourney: IInstanceSettings | null = null;
  public originalIsSchedulingOn: boolean = false;

  public isLoading: boolean = false;

  public async mounted(): Promise<void> {
    this.$v.$reset();
    this.documentId = this.$route.params.id;
    await this.loadInstanceJourney(this.documentId);
    if (this.instanceJourney) {
      this.initializeInstanceJourneyData(this.instanceJourney);
    }
  }

  public async loadInstanceJourney(documentId: string): Promise<void> {
    try {
      this.isLoading = true;
      const instanceJourney = await this.instanceJourneysService.getInstanceJourneysSetting(documentId);
      this.instanceJourney = instanceJourney;
    } catch (error) {
      showNotification(this, 'Erro ao obter as configurações da instância. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public get instanceJourneyConfig(): IInstanceSettings {
    return {
      instance: this.instanceName,
      branchName: this.branchName ?? '',
      journeys: this.journeys,
      documentId: this.documentId,
      schedule: this.isSchedulingOn,
    };
  }

  public initializeInstanceJourneyData(data: IInstanceSettings) {
    this.instanceName = data.instance ?? '';
    this.branchName = data.branchName ?? '';
    this.journeys = data.journeys;
    this.documentId = data.documentId ?? '';
    this.isSchedulingOn = data.schedule || false;
    this.originalIsSchedulingOn = this.isSchedulingOn;
  }

  public get isUserAdmin(): boolean {
    return isAdminForTool('ai-tag-manager');
  }

  public get isUserManagerOrAdmin(): boolean {
    return hasManagerOrAdminPermissionForTool('ai-tag-manager');
  }

  public async handleUpdateInstanceJourneyConfig(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid) {
      showNotification(this, 'Por favor, preencha todos os campos obrigatórios', 'is-warning', 'is-top', 5000);
      return;
    }
    if (this.isSchedulingOn !== this.originalIsSchedulingOn) {
      if (this.isSchedulingOn) {
        this.openDialogToConfirmEnablingClassification();
      } else {
        this.openDialogToConfirmDisablingClassification();
      }
      return;
    }
    await this.performUpdate(this.instanceJourneyConfig);
  }

  private async performUpdate(instanceJourneyConfig: IInstanceSettings) {
    try {
      this.isLoading = true;
      await this.instanceJourneysService.updateInstanceJourneysSettings(instanceJourneyConfig);
      handleResponse(this, 200, instanceJourneysServiceresponseMap);
      this.$router.push({ name: 'Instance-Journeys' });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.data.error === 'activation_without_evaluation') {
          showNotification(this, 'Não é possível ligar a classicação em uma instância sem avaliações concluídas.', 'is-danger', 'is-top', 5000);
        } else if (error.response?.data.error === 'administrator_only_action') {
          showNotification(this, 'Somente administradores podem alterar um instância que esteja sendo utilizada', 'is-danger', 'is-top', 5000);
        } else {
          showNotification(this, 'Erro ao atualizar a instância. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
        }
      }
    } finally {
      this.isLoading = false;
    }
  }

  public openDialogToConfirmEnablingClassification(): void {
    this.$buefy.dialog.confirm({
      title: 'Atenção!',
      confirmText: 'Ligar',
      cancelText: 'Cancelar',
      type: 'is-warning',
      hasIcon: true,
      message:
        'Ao ligar a classificação na instância, você está ciente de que todos os dias serão realizadas importações, classificações e exportações de dados para a instância em questão, tem certeza que deseja ligar a classificação?',
      onConfirm: () => this.performUpdate(this.instanceJourneyConfig),
      onCancel: () => {
        this.isSchedulingOn = this.originalIsSchedulingOn;
      },
    });
  }

  public openDialogToConfirmDisablingClassification(): void {
    this.$buefy.dialog.confirm({
      title: 'Atenção!',
      confirmText: 'Desligar',
      cancelText: 'Cancelar',
      type: 'is-warning',
      hasIcon: true,
      message:
        'Ao desligar a classificação na instância, você está ciente de que não haverão mais importações, classificações e exportações de comentários na instância em questão, tem certeza que deseja desligar a classificação?',
      onConfirm: () => this.performUpdate(this.instanceJourneyConfig),
      onCancel: () => {
        this.isSchedulingOn = this.originalIsSchedulingOn;
      },
    });
  }
}
</script>
