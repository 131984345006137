<template>
  <div id="app" class="is-flex is-flex-direction-column">
    <b-navbar type="is-primary">
      <template slot="start">
        <b-navbar-item @click="toggleSidebar">
          <b-icon icon="robot" size="is-medium"></b-icon>
        </b-navbar-item>
        <b-navbar-item tag="router-link" to="/"><h1 class="is-size-5">AI Tag Manager</h1></b-navbar-item>
      </template>
    </b-navbar>
    <div class="is-flex is-flex-grow-1 has-background-light">
      <b-sidebar class="flex-shrink" :open="sidebarVisible" fullheight position="static" mobile="hide" type="is-white">
        <b-menu>
          <b-menu-list>
            <b-menu-item icon="list-check" label="Instâncias e Jornadas" tag="router-link" to="/instance-journeys"></b-menu-item>
            <b-menu-item icon="code-branch" label="Branches" tag="router-link" to="/branches"></b-menu-item>
            <b-menu-item icon="memo-circle-check" label="Avaliações" tag="router-link" to="/evaluations"></b-menu-item>
            <b-menu-item v-if="HasForceClassification" icon="rotate-right" label="Forçar Classificação" tag="router-link" to="/force-classification"></b-menu-item>
          </b-menu-list>
        </b-menu>
      </b-sidebar>
      <div class="flex-grow container section">
        <router-view class="box" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { isAdminForTool } from './utils/validateUserUtils';

@Component
export default class App extends Vue {
  public sidebarVisible: boolean = true;

  public toggleSidebar(): void {
    this.sidebarVisible = !this.sidebarVisible;
  }

  public get HasForceClassification(): boolean {
    return isAdminForTool('ai-tag-manager');
  }
}
</script>
<style>
html,
body {
  height: 100% !important;
  margin: 0;
}

#app {
  min-height: 100% !important;
}

blockquote {
  white-space: pre-wrap;
}
</style>
