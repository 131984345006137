<template>
  <div class="container is-max-desktop">
    <div class="title">Nova Avaliação</div>
    <b-steps v-model="activeStep" :animated="isAnimated" :rounded="isRounded" :has-navigation="hasNavigation" destroy-on-hide>
      <EvaluationNameStep @nextStep="nextStep" />
      <EvaluationConfigStep @importedComments="nextStep" :evaluationId="evaluationId" />
      <EvaluationCommentsStep @nextStep="nextStep" @previousStep="previousStep" :comments="comments" :evaluationId="evaluationId" />
      <EvaluationConfigBranchStep @previousStep="previousStep" :evaluationId="evaluationId" />
    </b-steps>
  </div>
</template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { IEvaluationComment, IBranch, TestComment, IEvaluationConfig } from '@/models';
import EvaluationNameStep from '@/app/Components/Evaluations/EvaluationSteps/EvaluationNameStep.vue';
import EvaluationConfigStep from '@/app/Components/Evaluations/EvaluationSteps/EvaluationConfigStep.vue';
import EvaluationCommentsStep from './EvaluationSteps/EvaluationCommentsStep.vue';
import EvaluationConfigBranchStep from './EvaluationSteps/EvaluationConfigBranchStep.vue';

@Component({
  components: { EvaluationNameStep, EvaluationConfigStep, EvaluationCommentsStep, EvaluationConfigBranchStep },
})
export default class EvaluationAdd extends Vue {
  public activeStep: number = 0;
  public isAnimated: boolean = true;
  public isRounded: boolean = true;
  public hasNavigation: boolean = false;

  public evaluationId: string = '';
  public comments: IEvaluationComment[] = [];
  public testComment: TestComment = {
    instance: '',
    journey: '',
    tags: [],
    text: '',
  };
  public activeTabIndex: number = 0;
  public selectedBranch: IBranch | null = null;

  public nextStep(data: { evaluationId?: string; evaluationConfig?: IEvaluationConfig; comments: IEvaluationComment[] }): void {
    switch (this.activeStep) {
      case 0:
        if (data.evaluationId) {
          this.evaluationId = data.evaluationId;
          this.activeStep++;
        }
        break;
      case 1:
        if (data.comments) {
          this.comments = data.comments;
          this.activeStep++;
        }
        break;
      case 2:
        this.activeStep++;
        break;
      case 3:
        this.activeStep++;
        break;
    }
  }

  public previousStep(): void {
    this.activeStep--;
  }
}
</script>
