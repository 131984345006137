<template>
  <div></div>
</template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { jwtDecode } from 'jwt-decode';

@Component({
  components: {},
})
export default class Validate extends Vue {
  public mounted() {
    try {
      const token = window.location.hash.length ? window.location.hash.substr(1) : '';
      if (!token) this.redirectToAuth();
      jwtDecode(token);
      localStorage.setItem('token', token);
      this.$router.push({ name: 'Branches' });
    } catch (error) {
      this.redirectToAuth();
    }
  }

  redirectToAuth(): void {
    const baseUrl = 'https://ferramentas.';
    const domain = window.location.href.includes('staging') ? 'staging.solucx.com.br' : 'solucx.com.br';
    window.location.href = `${baseUrl}${domain}/auth/#/`;
  }
}
</script>
<style lang="css">
.headerToast {
  padding: 15px 0px 0px 15px !important;
  background: #f25600 !important;
  color: #fff !important;
  font-size: 14px;
}

.bodyToast {
  padding: 5px 0px 15px 15px !important;
  background: #f25600 !important;
  color: #fff !important;
  font-size: 12px;
}
</style>
