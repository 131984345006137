<template>
  <div class="container is-fullhd">
    <b-table detailed :data="filteredBranches" :hoverable="isHoverable" :paginated="isPaginated" :per-page="perPage" :current-page.sync="currentPage" :pagination-rounded="isPaginationRounded" :loading="isLoading">
      <div class="is-flex is-justify-content-space-between is-align-items-center">
        <SearchInput v-model="searchQuery" placeholder="Buscar Branch..." />
        <div>
          <b-switch v-model="filterArchived" @input="applyFilters"> Arquivadas </b-switch>
        </div>
      </div>
      <b-table-column field="name" label="Nome da branch" v-slot="{ row }">
        <template v-if="!row.archived">
          <router-link :to="{ name: 'Branch-Edit', params: { id: row.documentId } }">
            <a href="#" class="has-text-info has-text-weight-bold">
              {{ row.name }}
            </a>
          </router-link>
          <div class="is-size-7 is-justify-content-center">{{ row.description }}</div>
        </template>
        <template v-else>
          <span class="has-text-info has-text-weight-bold">{{ row.name }}</span>
          <div class="is-size-7 is-justify-content-center">{{ row.description }}</div>
        </template>
      </b-table-column>
      <b-table-column field="aiDefaultService" label="Serviço de Classificação" v-slot="{ row }" centered>
        <b-taglist class="is-justify-content-center">
          {{ row.aiDefaultService }}
        </b-taglist>
      </b-table-column>
      <b-table-column field="beingUsedBy" centered label="Utilizado por:" v-slot="{ row }">
        <div v-if="row.beingUsedBy && row.beingUsedBy.length > 0">
          <router-link :to="{ name: 'Instance-Journey-Edit', params: { id: row.beingUsedBy[0].documentId } }">
            <a href="#" class="has-text-info has-text-weight-bold">
              <b-tag :rounded="isRounded" v-for="(user, index) in row.beingUsedBy" :key="index" type="is-light is-info">{{ user.name }}</b-tag>
            </a>
          </router-link>
        </div>
        <div v-else>
          <b-tag type="is-light" rounded>Disponível</b-tag>
        </div>
      </b-table-column>
      <b-table-column v-if="isUserAdminOrManager" v-slot="{ row }" field="actions" label="Ações">
        <b-dropdown v-model="showActionsDropdown" position="is-bottom-left" trigger="is-dark">
          <b-button class="button is-small" type=" is-light" slot="trigger">
            <b-icon icon="fas fa-ellipsis-v"></b-icon>
          </b-button>
          <b-dropdown-item v-if="!row.archived" @click="openModalFromBranchDeleteComponent(row)" :disabled="!isUserAdminOrManager">
            <b-icon icon="box-archive" type="is-danger" outlined></b-icon>
            <span> Arquivar </span>
          </b-dropdown-item>
          <b-dropdown-item v-if="row.archived" @click="openModalFromUnarchiveBranch(row)" :disabled="!isUserAdminOrManager">
            <b-icon icon="box-archive" type="is-danger" outlined></b-icon>
            <span> Desarquivar </span>
          </b-dropdown-item>
          <b-dropdown-item @click="loadBranchesLogs(row.documentId)">
            <b-icon icon="database" type="is-primary" outlined></b-icon>
            <span> Ver Logs </span>
          </b-dropdown-item>
        </b-dropdown>
      </b-table-column>
      <template #detail="{ row }">
        <b-taglist>
          <b-tag :rounded="isRounded" v-for="(tag, index) in row.tags" :key="index" type="is-light is-primary">{{ tag }}</b-tag>
        </b-taglist>
      </template>
      <template #loading>
        <div class="has-text-centered">
          <p>Carregando...</p>
        </div>
      </template>
      <template #empty>
        <div v-if="!isLoading" class="has-text-centered">Não existem registros a serem exibidos</div>
      </template>
    </b-table>
    <router-link :to="{ name: 'Branch-Add' }">
      <b-button class="fixed-bottom-right" icon-left="plus" type="is-primary"></b-button>
    </router-link>
  </div>
</template>
<script lang="ts">
import { Vue, Component, Inject } from 'vue-property-decorator';
import BranchLogs from '@/app/Components/Branch/BranchLogs.vue';
import CustomPagination from '@/app/Components/Commons/CustomPagination.vue';
import BranchesService from '@/services/BranchesService';
import SearchInput from '../Commons/SearchInput.vue';
import { IAiConfig, IBranch } from '@/models';
import { showNotification, handleResponse, updateBranchResponseMap, archiveBranchResponseMap } from '@/utils/notificationUtils';
import { hasManagerOrAdminPermissionForTool } from '@/utils/validateUserUtils';
import axios from 'axios';

@Component({
  components: { BranchLogs, CustomPagination, SearchInput },
})
export default class BranchTable extends Vue {
  @Inject() private branchesService!: BranchesService;

  public branches: IBranch[] = [];
  public branchLogs: string[] = [];
  public receivedAiConfig: IAiConfig = {
    name: null,
    description: null,
    tags: [],
    correlationTags: [],
    aiDefaultService: null,
    aiServiceSettings: {
      openAi: {
        model: null,
        promptSys: '',
        promptUser: '',
      },
    },
  };
  public selectedBranch: IBranch[] = [];
  public isLoading: boolean = false;
  public isHoverable: boolean = true;
  public isRounded: boolean = true;

  public currentPage: number = 1;
  public selectedPerPage: number = 10;
  public isPaginationRounded: boolean = true;
  public userRole: string | null = '';

  public filterArchived: boolean = false;
  public searchQuery: string = '';
  public showActionsDropdown: boolean = false;

  public isPaginated: boolean = true;
  public perPage: number = 10;
  public token: string | null = null;

  public get isUserAdminOrManager(): boolean {
    return hasManagerOrAdminPermissionForTool('ai-tag-manager');
  }

  public async mounted(): Promise<void> {
    this.token = localStorage.getItem('token');
    await this.loadBranches();
  }

  public get filteredBranches(): IBranch[] {
    if (!this.searchQuery) return this.branches;
    return this.branches.filter((branch) => {
      return (branch.name ?? '').toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  }

  public async loadBranches(): Promise<void> {
    this.isLoading = true;
    try {
      axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;
      const branchesData = await this.branchesService.getBranches();
      if (branchesData) {
        this.branches = branchesData;
      }
    } catch (error) {
      showNotification(this, 'Não foi possível obter a lista de branches. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public async loadBranchesLogs(documentId: string) {
    try {
      const branchesLogsData = await this.branchesService.getBranchesLogs(documentId);
      if (branchesLogsData) {
        this.openModalFromBranchLogsComponent(branchesLogsData, documentId);
      }
    } catch (error) {
      showNotification(this, 'Não foi possível obter a lista de logs. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  public async applyFilters(): Promise<void> {
    try {
      this.branches = await this.branchesService.getBranches(this.filterArchived);
    } catch (error) {
      showNotification(this, 'Não foi realizar o filtro. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    }
  }

  public async handleDeleteBranchConfig(documentId: string): Promise<void> {
    this.isLoading = true;

    const branch = this.branches.find((branch) => branch.documentId === documentId);

    if ((branch?.beingUsedBy ?? []).length > 0) {
      showNotification(this, 'Não é possível arquivar uma branch que está sendo utilizada', 'is-danger', 'is-top', 5000);
    } else {
      try {
        const response = await this.branchesService.deleteBranchConfig(documentId);
        handleResponse(this, response, archiveBranchResponseMap);
        await this.loadBranches();
      } catch (error) {
        showNotification(this, 'Não foi possível arquivar a branch. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
      } finally {
        this.isLoading = false;
      }
    }
  }

  public async handleUnarchiveBranch(branch: IBranch): Promise<void> {
    this.isLoading = true;
    try {
      const response = await this.branchesService.updateBranchConfig(branch);
      handleResponse(this, response, updateBranchResponseMap);
      await this.loadBranches();
      if (this.filterArchived) {
        this.filterArchived = false;
      }
    } catch (error) {
      showNotification(this, 'Erro ao desarquivar a branch. Tente novamente mais tarde', 'is-danger', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public openModalFromBranchLogsComponent(logs: string[], documentId: string): void {
    this.$router.push({ name: 'Branch-Logs', params: { id: documentId } });
    const modalInstance = this.$buefy.modal.open({
      parent: this,
      component: BranchLogs,
      hasModalCard: false,
      trapFocus: true,
      canCancel: ['escape', 'button', 'outside'],
      props: { logs: logs },
      events: {
        close: () => {
          this.$router.push({ name: 'Branches' });
          modalInstance.close();
        },
      },
    });
  }

  public openModalFromBranchDeleteComponent(selectedBranch: IBranch): void {
    this.$buefy.dialog.confirm({
      title: 'Atenção!',
      confirmText: 'Arquivar',
      cancelText: 'Cancelar',
      type: 'is-danger',
      message: 'Tem certeza que deseja arquivar a branch?',
      onConfirm: () => this.handleDeleteBranchConfig(selectedBranch.documentId),
    });
  }

  public openModalFromUnarchiveBranch(selectedBranch: IBranch): void {
    this.$buefy.dialog.confirm({
      title: 'Atenção!',
      confirmText: 'Desarquivar',
      cancelText: 'Cancelar',
      type: 'is-success',
      message: 'Tem certeza que deseja desarquivar a branch?',
      onConfirm: () => {
        const branchToUpdate = { ...selectedBranch, archived: false };
        this.handleUnarchiveBranch(branchToUpdate);
      },
    });
  }
}
</script>
<style scoped>
.fixed-bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 100px !important;
}
</style>
