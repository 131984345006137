import { AxiosResponse } from 'axios';
import { IBranch, IEvaluation, IEvaluationResults, IEvaluationComment, ICommentTags, FeedbackSubmission, IEvaluationConfig, ForceClassificationResponse } from '@/models';
import BaseService from '@/services/BaseService';

export default class EvaluationService extends BaseService {
  public async getEvaluation(documentId: string): Promise<IEvaluation> {
    const { data } = await this.client.get(`/evaluations/${documentId}`);
    return data;
  }

  public async getEvaluations(): Promise<IEvaluation[]> {
    const { data } = await this.client.get<IEvaluation[]>('/evaluations');
    return data;
  }

  public async getEvaluationResults(documentId: string): Promise<IEvaluationResults> {
    const { data } = await this.client.get<IEvaluationResults>(`/evaluations/${documentId}/results`);
    return data;
  }

  public async downloadEvaluationResultsToCSV(documentId: string): Promise<AxiosResponse<string>> {
    const response = await this.client.get<string>(`/evaluations/${documentId}/results/csv`, { responseType: 'blob' });
    return response;
  }

  public async approveEvaluation(documentId: string, approve: boolean): Promise<void> {
    await this.client.post(`/evaluations/${documentId}/approve`, { approve });
  }

  public async disapproveEvaluation(documentId: string, approve: boolean): Promise<void> {
    await this.client.post(`/evaluations/${documentId}/approve`, { approve });
  }

  public async calculateScore(documentId: string, approved: FeedbackSubmission[]): Promise<void> {
    const { data } = await this.client.post(`/evaluations/${documentId}/calculate-score`, approved);
    return data;
  }

  public async saveObservation(documentId: string, text: string): Promise<void> {
    await this.client.post(`/evaluations/${documentId}/observations`, { text });
  }

  public async createNewEvaluation(name: string): Promise<string> {
    const { data } = await this.client.post<{ documentId: string }>('/evaluations', { name });
    return data.documentId;
  }

  public async importComments(evaluationConfig: IEvaluationConfig): Promise<void> {
    const { documentId } = evaluationConfig;
    await this.client.post(`/evaluations/${documentId}/import-comments`, evaluationConfig);
  }

  public async getEvaluationImportedComments(evaluationId: string): Promise<IEvaluationComment[]> {
    const documentId = evaluationId;
    const { data } = await this.client.get<IEvaluationComment[]>(`/evaluations/${documentId}/comments`);
    return data;
  }

  public async getEvaluationsLogs(documentId: string) {
    const { data } = await this.client.get(`/evaluations/${documentId}/logs`);
    return data;
  }

  public async deleteImportedComments(evaluationId: string): Promise<void> {
    const documentId = evaluationId;
    await this.client.delete(`/evaluations/${documentId}/import-comments`);
  }

  public async submitTestCommentForPreview(evaluationId: string, selectedBranch: IBranch): Promise<ICommentTags> {
    const { data } = await this.client.post<ICommentTags>(`/evaluations/${evaluationId}/try`, selectedBranch);
    return data;
  }

  public async submitEvaluationConfig(evaluationId: string, selectedBranch: IBranch): Promise<void> {
    await this.client.post(`/evaluations/${evaluationId}/evaluate`, selectedBranch);
  }

  public async forceClassification(instanceConfig: IEvaluationConfig): Promise<ForceClassificationResponse> {
    const { data } = await this.client.post('/evaluations/force-classification', instanceConfig);
    return data;
  }
}
