import Vue from 'vue';
import Vuelidate from 'vuelidate';
import App from '@/App.vue';
import router from '@/router/index';
import Buefy from 'buefy';
import ServicesInitializer from '@/services/ServicesInitializer';
import VueI18n from 'vue-i18n';
import '@/assets/styles/main.scss';

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(Buefy, { defaultIconPack: 'fa-regular' });
Vue.use(VueI18n);

Vue.prototype.$userDetails = '';

const i18n = new VueI18n({ locale: 'pt-BR' });
const userToken = localStorage.getItem('token') ?? '';
const serviceInitializer = new ServicesInitializer();
new Vue({
  i18n,
  router,
  render: (h) => h(App),
  provide() {
    return {
      ...serviceInitializer.getServices(userToken),
    };
  },
}).$mount('#app');
