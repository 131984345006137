<template>
  <div>
    <div class="columns">
      <div class="column content">
        <div class="mb-1">
          <h3 id="instanceName">{{ evaluationResults.name }}</h3>
          <b>Nome da instância:</b>
          <router-link id="instanceLink" :to="{ name: 'Instance-Journey-Edit', params: { id: evaluationResults.instanceId } }">
            <a href="#" class="has-text-info has-text-weight-bold">
              <b-tag type="is-info is-light">{{ evaluationResults.instance }}</b-tag>
            </a>
          </router-link>
        </div>
        <div class="mb-1">
          <b>Jornadas:</b>
          <b-tag>{{ getJourneys() }}</b-tag>
        </div>
        <div class="mb-1" v-if="hasImportPeriod">
          <b>Período da importação:</b> <b-tag> {{ getFormattedStartDate() }}</b-tag> / <b-tag>{{ getFormattedEndDate() }}</b-tag>
        </div>
        <div class="mb-1">
          <b>Operador:</b> <b-tag>{{ evaluationResults.operator }}</b-tag>
        </div>
        <div class="mb-1" v-if="hasEvaluatedAt">
          <b>Data da avaliação:</b> <b-tag>{{ getFormattedEvaluatedAt() }}</b-tag>
        </div>
        <div class="mb-1">
          <b>Branch utilizada:</b>
          <router-link id="branchLink" :to="{ name: 'Branch-Edit', params: { id: evaluationResults.branchId } }">
            <a href="#" class="has-text-info has-text-weight-bold">
              <b-tag type="is-info is-light">{{ evaluationResults.branchName }}</b-tag>
            </a>
          </router-link>
        </div>
        <div>
          <b>Score:</b> <b-tag :type="getScoreStyle(evaluationResults.score || 0).type"> {{ scoreAsPercentage }}</b-tag>
        </div>
      </div>
      <div class="column">
        <b-field><textarea is-full-width class="textarea" v-model="observation" placeholder="Observações"></textarea></b-field>
        <div class="is-flex is-justify-content-space-between">
          <b-field><b-button type="is-primary" @click="handleSubmitSaveObservation(observation)">Salvar</b-button></b-field>
          <b-field><b-button icon-left="file-csv" id="downloadCSV" type="is-primary" @click="handleSubmitDownloadResultsToCSV(documentId)">Baixar CSV</b-button></b-field>
        </div>
      </div>
    </div>
    <b-table :data="evaluationResults.results" :loading="isLoading">
      <b-table-column field="journey" label="Jornada" v-slot="{ row }">
        <b-tag class="is-primary is-light">{{ row.journey }}</b-tag>
      </b-table-column>
      <b-table-column field="tags" label="Resultados" v-slot="{ row }">
        <div class="content">
          <blockquote>{{ row.text }}</blockquote>
          <b-taglist>
            <b-tag class="is-small" v-for="(tag, index) in row.tags" :key="index" :icon="getTagStyle(tag.sentiment).icon" :type="getTagStyle(tag.sentiment).type">
              {{ tag.tag }}
            </b-tag>
          </b-taglist>
        </div>
      </b-table-column>
      <b-table-column field="approve" label="Avaliação do usuário" centered>
        <template v-slot="{ row, index }">
          <b-field>
            <b-radio-button :id="'approved-btn-' + index" v-model="row.approved" :native-value="true" :disabled="!isUserAdminOrManager" type="is-success is-light is-outlined">
              <b-icon icon="check"></b-icon>
              <span>Certo</span>
            </b-radio-button>
            <b-radio-button :id="'na-btn-' + index" v-model="row.approved" :native-value="null" :disabled="!isUserAdminOrManager" type="is-warning is-light is-outlined">
              <b-icon icon="minus"></b-icon>
              <span>N/A</span>
            </b-radio-button>
            <b-radio-button :id="'disapproved-btn-' + index" v-model="row.approved" :native-value="false" :disabled="!isUserAdminOrManager" type="is-danger is-light is-outlined">
              <b-icon icon="close"></b-icon>
              <span>Errado</span>
            </b-radio-button>
          </b-field>
        </template>
      </b-table-column>
      <template #empty>
        <div class="has-text-centered">No records</div>
      </template>
    </b-table>
    <div class="mt-5 is-flex is-justify-content-right">
      <b-button type="is-success" class="mr-2" :disabled="!isUserAdminOrManager" @click="handleCalculateScore">Calcular Score</b-button>
      <b-button type="is-primary" class="ml-2" :disabled="!isUserAdmin" @click="openDialogComponentToApproveEvaluation" v-if="!evaluationResults.approved"> Aprovar Avaliação </b-button>
      <b-button type="is-danger" class="ml-2" :disabled="!isUserAdmin" @click="openDialogComponentToDisapproveEvaluation" v-if="evaluationResults.approved"> Desaprovar Avaliação </b-button>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Inject, Vue } from 'vue-property-decorator';
import EvaluationService from '@/services/EvaluationService';
import { FeedbackSubmission, IEvaluationResults } from '@/models';
import { showNotification } from '@/utils/notificationUtils';
import { hasManagerOrAdminPermissionForTool, isAdminForTool } from '@/utils/validateUserUtils';

@Component({})
export default class EvaluationResult extends Vue {
  @Inject() private evaluationService!: EvaluationService;

  public evaluationResults: IEvaluationResults = {
    name: '',
    evaluatedAt: '',
    operator: '',
    instance: '',
    instanceId: '',
    branchName: '',
    branchId: '',
    startDate: '',
    endDate: '',
    observation: '',
    results: [],
    approved: false,
    score: 0,
  };
  public observation: string = '';
  public approve: boolean = true;
  public isLoading: boolean = true;
  public isCorrect: boolean = false;

  public get documentId(): string {
    return this.$route.params.id;
  }

  public get hasImportPeriod(): boolean {
    return !!this.evaluationResults?.startDate && !!this.evaluationResults?.endDate;
  }

  public get hasEvaluatedAt(): boolean {
    return !!this.evaluationResults?.evaluatedAt;
  }

  public get isUserAdminOrManager(): boolean {
    return hasManagerOrAdminPermissionForTool('ai-tag-manager');
  }

  public get isUserAdmin(): boolean {
    return isAdminForTool('ai-tag-manager');
  }

  public get scoreAsPercentage(): string {
    if (this.evaluationResults?.score !== undefined) {
      return `${(this.evaluationResults.score * 100).toFixed(0)}%`;
    }
    return '0';
  }

  public async mounted(): Promise<void> {
    try {
      this.isLoading = true;
      await this.loadEvaluationResults();
    } catch (error) {
      showNotification(this, 'Erro ao carregar os resultados da avaliação', 'is-warning', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  private async loadEvaluationResults(): Promise<void> {
    const response = await this.evaluationService.getEvaluationResults(this.documentId);
    this.evaluationResults = response;
    this.syncObservation();
    this.initializeUserFeedback();
  }

  private syncObservation(): void {
    if (this.evaluationResults?.observation) {
      this.observation = this.evaluationResults.observation;
    }
  }

  private initializeUserFeedback(): void {
    if (this.evaluationResults?.results) {
      this.evaluationResults.results.forEach((result) => {
        if (result.approved === undefined) {
          this.$set(result, 'approved', null);
        }
      });
    }
  }

  public prepareDataForBackend(): FeedbackSubmission[] {
    return (this.evaluationResults?.results || []).map((result) => ({
      transactionId: result.transactionId,
      approved: result.approved,
    }));
  }

  public getTagStyle(sentiment: string): { icon?: string; type?: string } {
    switch (sentiment) {
      case 'positive':
        return { icon: 'thumbs-up', type: 'is-success is-light' };
      case 'negative':
        return { icon: 'thumbs-down', type: 'is-danger is-light' };
      case 'neutral':
        return { icon: 'thumbs-info', type: 'is-warning is-light' };
      default:
        return {};
    }
  }

  public getScoreStyle(score: number): { type: string } {
    if (score >= 0.7) {
      return { type: 'is-success is-light' };
    } else if (score >= 0.5) {
      return { type: 'is-warning is-light' };
    } else if (score < 0.5) {
      return { type: 'is-danger is-light' };
    } else {
      return { type: 'is-white is-light' };
    }
  }

  public formatDate(date: string): string {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }

  public getJourneys(): string {
    if (this.evaluationResults?.results) {
      const journeys = new Set();
      this.evaluationResults?.results.forEach((result) => {
        journeys.add(result.journey);
      });
      const diferentJourneys = [...journeys];
      const diferentJourneysString = diferentJourneys.join(', ');
      return diferentJourneysString;
    }
    return '';
  }

  public getFormattedStartDate(): string {
    if (this.evaluationResults?.startDate) {
      return this.formatDate(this.evaluationResults.startDate);
    }
    return '';
  }

  public getFormattedEndDate(): string {
    if (this.evaluationResults?.endDate) {
      return this.formatDate(this.evaluationResults.endDate);
    }
    return '';
  }

  public getFormattedEvaluatedAt(): string {
    if (this.evaluationResults?.evaluatedAt) {
      return this.formatDate(this.evaluationResults.evaluatedAt.split('T')[0]);
    }
    return '';
  }

  public async handleSubmitDownloadResultsToCSV(documentId: string): Promise<void> {
    try {
      const response = await this.evaluationService.downloadEvaluationResultsToCSV(documentId);
      if (response === undefined) {
        throw new Error('Response is undefined');
      }
      if (typeof response === 'number') {
        throw new Error(`Error status: ${response}`);
      }
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const archiveName = response.headers['content-disposition'].split('filename=')[1];
      link.setAttribute('download', archiveName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.loadEvaluationResults();
      showNotification(this, 'Arquivo baixado com sucesso', 'is-success', 'is-top', 5000);
    } catch (error) {
      showNotification(this, 'Erro ao baixar os resultados da avaliação', 'is-warning', 'is-top', 5000);
    }
  }

  public async handleCalculateScore(): Promise<void> {
    try {
      this.isLoading = true;

      const feedbackData = this.prepareDataForBackend();
      await this.evaluationService.calculateScore(this.documentId, feedbackData);

      this.loadEvaluationResults();
      showNotification(this, 'Score calculado com sucesso', 'is-success', 'is-top', 5000);
    } catch (error) {
      showNotification(this, 'Erro ao aprovar calcular o score', 'is-warning', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public async handleSubmitSaveObservation(observation: string): Promise<void> {
    try {
      this.isLoading = true;
      await this.evaluationService.saveObservation(this.documentId, observation);
      this.loadEvaluationResults();
      showNotification(this, 'Observação salva com sucesso', 'is-success', 'is-top', 5000);
    } catch (error) {
      showNotification(this, 'Erro ao salvar a observação', 'is-warning', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public async handleSubmitApproveEvaluation(approve: boolean): Promise<void> {
    try {
      this.isLoading = true;
      await this.evaluationService.approveEvaluation(this.documentId, approve);
      this.loadEvaluationResults();
      showNotification(this, 'Avaliação aprovada com sucesso', 'is-success', 'is-top', 5000);
    } catch (error) {
      showNotification(this, 'Erro ao aprovar a avaliação', 'is-warning', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public async handleSubmitDisapproveEvaluation(approve: boolean): Promise<void> {
    try {
      this.isLoading = true;
      await this.evaluationService.disapproveEvaluation(this.documentId, approve);
      this.loadEvaluationResults();
      showNotification(this, 'Avaliação desaprovada com sucesso', 'is-success', 'is-top', 5000);
    } catch (error) {
      showNotification(this, 'Erro ao desaprovar a avaliação', 'is-warning', 'is-top', 5000);
    } finally {
      this.isLoading = false;
    }
  }

  public openDialogComponentToApproveEvaluation(): void {
    this.approve = true;
    this.$buefy.dialog.confirm({
      title: 'Atenção!',
      confirmText: 'Aprovar',
      cancelText: 'Cancelar',
      type: 'is-warning',
      message:
        'Ao aprovar uma avaliação você estará ligando as classificações na instância, isso implica que os comentários classificados diariamente pela IA serão inseridos na instância após serem classificados, tem certeza que deseja continuar?',
      hasIcon: true,
      onConfirm: () => this.handleSubmitApproveEvaluation(this.approve),
    });
  }

  public openDialogComponentToDisapproveEvaluation(): void {
    this.approve = false;
    this.$buefy.dialog.confirm({
      title: 'Atenção!',
      confirmText: 'Desaprovar',
      cancelText: 'Cancelar',
      type: 'is-warning',
      message: 'Desaprovar uma avaliação não irá evitar que os comentários sejam classificados e exportados para a instância, tem certeza que deseja continuar?',
      hasIcon: true,
      onConfirm: () => this.handleSubmitDisapproveEvaluation(this.approve),
    });
  }
}
</script>
